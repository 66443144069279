/* eslint-disable camelcase */
import { Typography, Box, Drawer, Fade, Modal, Backdrop } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { fDateTime } from 'utils/formatTime';
import { useMediaContext } from 'state/media';
import CloseIcon from '@mui/icons-material/Close';
import Iconify from 'components/iconify/Iconify';

const HeaderStyle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  marginBottom: 10,
}));

const CloseIconStyle = styled('div')(() => ({
  color: 'grey',
  ':hover': { color: 'black' },
}));

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '80vw',
  maxHeight: '90vh',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  overflow: 'scroll',
  p: 3,
};

ShowHistoryModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      timestamp: PropTypes.number,
      type: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

export default function ShowHistoryModal({ open, setOpen, history }) {
  const { isSmallScreen } = useMediaContext();
  const handleClose = () => {
    setOpen(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return isSmallScreen ? (
    <Drawer anchor="bottom" open={open} onClose={toggleDrawer(false)}>
      <Box p={2}>
        <HeaderStyle>
          <Typography variant="h5">Historial </Typography>
          <CloseIconStyle>
            <CloseIcon onClick={toggleDrawer(false)} />
          </CloseIconStyle>
        </HeaderStyle>

        {history.length > 0 ? (
          history?.map((item) => <MovementItem item={item} key={item.id} />)
        ) : (
          <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
            <Typography variant="subtitle1">No hay movimientos</Typography>
          </div>
        )}
      </Box>
    </Drawer>
  ) : (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={boxStyle}>
          <HeaderStyle>
            <Typography variant="h5">Historial </Typography>
            <CloseIconStyle>
              <CloseIcon onClick={toggleDrawer(false)} />
            </CloseIconStyle>
          </HeaderStyle>

          <div
            style={{
              height: '100%',
              overflow: 'scroll',
            }}
          >
            {history.length > 0 ? (
              history?.map((item) => <MovementItem item={item} key={item.id} />)
            ) : (
              <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                <Typography variant="subtitle1">No hay movimientos</Typography>
              </div>
            )}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

function MovementItem({ item }) {
  const theme = useTheme();

  return (
    <Grid
      container
      display="flex"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ marginBottom: 1 }}
    >
      <Grid xs={12} sm={5} md={5} display="flex" direction="row" alignItems="center">
        {item.type === 'income' ? (
          <Iconify icon="emojione-monotone:up-arrow" color="#60D03E" width={30} />
        ) : (
          <Iconify icon="emojione-monotone:down-arrow" color="#FF4842" width={30} />
        )}
        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column', width: ' 100% ' }}>
          <Typography variant="subtitle1">{item.description}</Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            {fDateTime(item.timestamp)}
          </Typography>
        </div>
      </Grid>
      <Grid
        xs={12}
        sm={4}
        md={5}
        sx={{
          [theme.breakpoints.down('md')]: {
            textAlign: 'start',
            marginLeft: '36px',
          },
        }}
      >
        <Typography variant="subtitle1">{item.travelId ? 'Viaje' : 'Realizado por'}</Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          {item.travelId ? item.travelId : item.made_for}
        </Typography>
      </Grid>
      <Grid xs={12} sm={3} md={2}>
        <Typography
          variant="subtitle1"
          sx={{
            color: item.type === 'income' ? '#60D03E' : '#FF4842',
            textAlign: 'end',
            [theme.breakpoints.down('md')]: {
              textAlign: 'start',
              marginLeft: '36px',
            },
          }}
        >
          {item.type === 'income' ? ' + ' : ' - '}$ {Intl.NumberFormat('en-DE').format(item.value || '0')}
        </Typography>
      </Grid>
    </Grid>
  );
}

MovementItem.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    timestamp: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.number,
    travelId: PropTypes.string,
    made_for: PropTypes.string,
  }),
};
