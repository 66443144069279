import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const FloatingButtonStyle = styled('div')(({ theme, size }) => ({
  position: 'fixed',
  bottom: 30,
  right: 30,
  ...(size === 'lg' && {
    minWidth: '25%',
    [theme.breakpoints.down('sm')]: {
      margin: '2vw',
      left: '0%',
      right: '0%',
      width: size === 'lg' && '96vw',
    },
  }),
}));

const FloatingButton = ({ text, onClick, startIcon, endIcon, size }) => (
  <FloatingButtonStyle size={size}>
    <Button type="submit" fullWidth variant="contained" startIcon={startIcon} onClick={onClick} endIcon={endIcon}>
      {text}
    </Button>
  </FloatingButtonStyle>
);

FloatingButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  size: PropTypes.oneOf(['lg', 'sm']),
};

export default FloatingButton;
