import { Card, Typography, CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import styled from 'styled-components';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import MotionPhotosOffOutlinedIcon from '@mui/icons-material/MotionPhotosOffOutlined';
import { useTheme } from '@mui/material/styles';
import ShowDocumentModal from '../../../components/modals/showDocumentModal';
import { getPermissions } from '../../../permissions';
import { validateTokenError } from '../../../actions/auth';
import { useMediaContext } from '../../../state/media';
import { CustomSnackbar } from '../../../components/ui/Snackbars';
import Iconify from '../../../components/iconify';
import { getDocuments } from '../../../actions/documentsActions';

DriverDocumentsCard.propTypes = {
  username: PropTypes.string,
  cardStyle: PropTypes.object,
};

export default function DriverDocumentsCard({ username, cardStyle }) {
  const { isSmallScreen } = useMediaContext();
  const [loading, setLoading] = useState(false);

  const [documents, setDocuments] = useState({
    'profile-picture': {
      url: null,
      name: 'Foto de perfil',
      status: 'no-info',
    },
    'dni-photo': {
      url: null,
      name: 'Foto con cédula',
      status: 'no-info',
      comments: null,
    },
    'dni-front': {
      url: null,
      name: 'Cédula de ciudadanía - Frontal',
      status: 'no-info',
      comments: null,
    },
    'dni-back': {
      url: null,
      name: 'Cédula de ciudadanía - Trasera',
      status: 'no-info',
      comments: null,
    },
    'driver-license-front': {
      url: null,
      name: 'Licencia de conducción - Frontal',
      status: 'no-info',
      comments: null,
    },
    'driver-license-back': {
      url: null,
      name: 'Licencia de conducción - Trasera',
      status: 'no-info',
      comments: null,
    },
    'vehicle-ownership': {
      url: null,
      name: 'Tarjeta de propiedad',
      status: 'no-info',
      comments: null,
    },
    soat: {
      url: null,
      name: 'SOAT',
      status: 'no-info',
      comments: null,
    },
  });
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({});

  const [permissions, setPermissions] = useState({
    read: false,
    write: false,
    edit: false,
    delete: false,
  });
  const [snackbarOptions, setSnackbarOptions] = useState({
    open: false,
    message: '',
    type: '',
  });

  const setDocumentStatus = (data) => {
    const result = documents;
    Object.entries(data).forEach(([, value]) => {
      const id = value.Key.split('/').reverse()[0].split('.')[0];
      result[id].key = value.Key;
      result[id].status = value.status;
      result[id].comments = value.comments;
      result[id].url = value.Location;
    });
    setDocuments(result);
  };

  const getDriverDocuments = () => {
    if (username) {
      setLoading(true);
      getDocuments(username)
        .then((data) => {
          setDocumentStatus(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.data.error !== 'Documentation not found') {
            console.error(error);
            validateTokenError(error);
            setSnackbarOptions({
              open: true,
              message: 'Error consultando los documentos',
              type: 'error',
            });
          }
        });
    }
  };

  useEffect(() => {
    setPermissions(getPermissions('documents'));
    getDriverDocuments();
  }, []);

  return (
    permissions.read && (
      <Card sx={cardStyle}>
        <ShowDocumentModal
          open={showDocumentModal}
          setOpen={setShowDocumentModal}
          document={selectedDocument}
          username={username}
          onSuccess={() => {
            getDriverDocuments();
          }}
          onError={() => {
            setSnackbarOptions({
              open: true,
              message: 'Error actualizando el documento',
              type: 'error',
            });
          }}
        />
        <CustomSnackbar options={snackbarOptions} setOptions={setSnackbarOptions} />
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Documentos</Typography>

          {!isSmallScreen && (
            <Grid container gap={2}>
              <Grid container gap={0.5} direction="row" justifyContent="center" alignItems="center">
                <TaskAltIcon color="success" fontSize="small" />
                <Typography variant="subtitle1" sx={{ color: '#60D03E' }}>
                  Aprobado
                </Typography>
              </Grid>
              <Grid container gap={0.5} direction="row" justifyContent="center" alignItems="center">
                <AccessTimeIcon color="warning" fontSize="small" />
                <Typography variant="subtitle1" sx={{ color: '#FFC107' }}>
                  Revisión
                </Typography>
              </Grid>
              <Grid container gap={0.5} direction="row" justifyContent="center" alignItems="center">
                <CancelOutlinedIcon color="error" fontSize="small" />
                <Typography variant="subtitle1" sx={{ color: '#FF4842' }}>
                  Rechazado
                </Typography>
              </Grid>
              <Grid container gap={0.5} direction="row" justifyContent="center" alignItems="center">
                <MotionPhotosOffOutlinedIcon fontSize="small" sx={{ color: 'grey' }} />
                <Typography variant="subtitle1" sx={{ color: 'grey' }}>
                  No info
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>

        {loading ? (
          <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Grid container sx={{ marginTop: 2 }} columnGap={10} rowGap={1} justifyContent={'center'}>
            {Object.keys(documents).map((key) => (
              <Grid item="true" xs={12} sm={5} key={key}>
                <DocumentHeader
                  document={documents[key]}
                  key={key}
                  onClick={() => {
                    setSelectedDocument(documents[key]);
                    setShowDocumentModal(true);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Card>
    )
  );
}

DocumentHeader.propTypes = {
  document: PropTypes.shape({
    key: PropTypes.string,
    comments: PropTypes.string,
    status: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
};

function DocumentHeader({ document, onClick }) {
  const theme = useTheme();
  const documentStatusIcon = (status) => {
    switch (status) {
      case 'review':
        return <AccessTimeIcon color="warning" fontSize="small" />;

      case 'approved':
        return <TaskAltIcon color="success" fontSize="small" />;

      case 'denied':
        return <CancelOutlinedIcon color="error" fontSize="small" />;

      default:
        return <MotionPhotosOffOutlinedIcon fontSize="small" sx={{ color: 'grey' }} />;
    }
  };

  return (
    <Item theme={theme} status={document.status}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingLeft={1}
        onClick={document.status !== 'no-info' ? onClick : null}
      >
        <div className="title">
          {documentStatusIcon(document.status)}
          <Typography variant="body2" sx={{ color: 'text.primary' }} noWrap>
            {document.name}
          </Typography>
        </div>
        {document.status !== 'no-info' && <Iconify icon="oui:arrow-right" />}
      </Grid>
    </Item>
  );
}

const Item = styled('div')(({ theme, status }) => ({
  ':hover': {
    backgroundColor: status !== 'no-info' ? '#F4F6F8' : 'transparent',
    borderRadius: 30,
  },
  '.title': {
    justifyContent: 'start',
    flexDirection: 'row',
    display: 'flex',
    gap: 10,
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
}));
