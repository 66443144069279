/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import { CustomSnackbar } from '../../components/ui/Snackbars';
import EditUserModal from '../../components/modals/editUserModal';
import { getUsers, getUsersCount, deleteUser } from '../../actions/usersActions';
import Label from '../../components/label/Label';
import Iconify from '../../components/iconify/Iconify';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import AlertDialog from '../../components/ui/Dialogs';
import { validateTokenError } from '../../actions/auth';
import { usePagePermissionCheck, getPermissions } from '../../permissions';
import { fDate } from '../../utils/formatTime';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nombre', alignRight: false },
  { id: 'email', label: 'Correo', alignRight: false },
  { id: 'phone_number', label: 'Celular', alignRight: false },
  { id: 'id', label: 'Username', alignRight: false },
  { id: 'created_at', label: 'Fecha Creación', alignRight: false },
  { id: 'status', label: 'Estado', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.phone_number.indexOf(query) !== -1 ||
        _user.id.indexOf(query) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Users() {
  usePagePermissionCheck('users');
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchCount, setSearchCount] = useState(0);
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [orderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [usersCount, setUsersCount] = useState(0);

  const [users, setUsers] = useState({});
  const [filteredData, setFilteredData] = useState([]);

  const [showEditModal, setShowEditModal] = useState(false);
  const [paginationTokens, setPaginationTokens] = useState({});

  const [permissions, setPermissions] = useState({
    read: false,
    write: false,
    edit: false,
    delete: false,
  });

  const [snackbarOptions, setSnackbarOptions] = useState({
    open: false,
    message: '',
    type: '',
  });

  const [dialogOptions, setDialogOptions] = useState({
    open: false,
    title: null,
    message: null,
    showCancelButton: false,
    cancelButtonFocused: false,
    onAcceptClick: null,
    onCancelClick: null,
  });

  const handleOpenMenu = (event, user) => {
    setSelectedUser(user);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setUsers({});
    setPaginationTokens({});
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const getStatus = (status) => {
    if (status === 'true') return 'Verificado';
    return 'No verficado';
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const isNotFound = !filteredData.length && !!filterName;

  const getUsersList = (customPage, customLimit) => {
    const currentPage = customPage || page;
    const limit = customLimit || rowsPerPage;

    getUsers(paginationTokens[currentPage], limit)
      .then((response) => {
        const { data, paginationToken } = { data: response.data.users, paginationToken: response.data.paginationToken };
        if (paginationToken) {
          setPaginationTokens((prevState) => ({
            ...prevState,
            [currentPage + 1]: paginationToken,
          }));
        }
        const result = users[currentPage] || [];
        data.forEach((item) => {
          const element = {
            id: item.sub,
            name: item.name,
            email: item.email,
            phone_number: item.phone_number,
            created_at: item.UserCreateDate,
            status: item.phone_number_verified,
          };
          const index = result.findIndex((user) => user.id === element.id);
          if (index === -1) {
            result.push(element);
          } else {
            result[index] = element;
          }
        });

        setUsers((prevState) => ({
          ...prevState,
          [currentPage]: result,
        }));

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        validateTokenError(error);
        setSnackbarOptions({
          open: true,
          message: 'Error consultando las usuarias',
          type: 'error',
        });
        setLoading(false);
      });
  };

  const deleteUserHandler = () => {
    deleteUser(selectedUser.id)
      .then((response) => {
        const body = response.data;
        if (body.ok === true) {
          getUsersList();
          setSnackbarOptions({
            open: true,
            message: 'Usuaria eliminada',
            type: 'success',
          });
          setDialogOptions({
            open: false,
            title: null,
            message: null,
            showCancelButton: false,
            cancelButtonFocused: false,
            onAcceptClick: null,
            onCancelClick: null,
          });
        } else {
          setSnackbarOptions({
            open: true,
            message: 'Error eliminando la usuaria',
            type: 'error',
          });
          console.error(response);
        }
      })
      .catch((error) => {
        validateTokenError(error);
        setSnackbarOptions({
          open: true,
          message: 'Error eliminando la usuaria',
          type: 'error',
        });
        console.error(error);
      });
  };

  CustomTableRow.propTypes = {
    row: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      phone_number: PropTypes.string,
      created_at: PropTypes.string,
      status: PropTypes.string,
    }).isRequired,
  };

  const setTotalUsersCount = async () => {
    const count = await getUsersCount();
    setUsersCount(count);
  };

  useEffect(() => {
    setPermissions(getPermissions('users'));
    setTotalUsersCount();
  }, []);

  function searchData() {
    if (filterName) {
      setSearching(true);
      let result = [];
      if (searchCount < usersCount / rowsPerPage) {
        if (users[searchCount]) {
          result = applySortFilter(users[searchCount], getComparator(order, orderBy), filterName);
          if (result.length === 0) {
            setSearchCount(searchCount + 1);
          } else {
            setSearching(false);
          }
        } else {
          getUsersList(searchCount);
        }
        setFilteredData(result);
      } else {
        setSearching(false);
      }
    } else {
      setSearchCount(0);
      setFilteredData([]);
    }
  }

  useEffect(() => {
    searchData();
  }, [filterName, users, searchCount]);

  useEffect(() => {
    if (!users[page]) {
      setLoading(true);
      getUsersList();
    }
  }, [page, rowsPerPage]);

  return (
    <>
      <Helmet>
        <title> Usuarias | Luguu </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Usuarias
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            refreshFunction={() => {
              getUsersList(page, rowsPerPage);
            }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={usersCount}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                />
                {loading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <CircularProgress color="primary" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {filteredData.length > 0
                      ? filteredData
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => <CustomTableRow row={row} key={row.id} />)
                      : !isNotFound && users[page]?.map((row) => <CustomTableRow row={row} key={row.id} />)}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                )}

                {searching ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <CircularProgress color="primary" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              No encontrado
                            </Typography>

                            <Typography variant="body2">
                              No se encontró ningún resultado para &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Intenta buscando por celular o username
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredData.length > 0 ? filteredData.length : usersCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <EditUserModal
        open={showEditModal}
        setOpen={setShowEditModal}
        user={selectedUser}
        onSuccess={() => {
          if (filteredData.length > 0) {
            setFilteredData([]);
            setFilterName('');
            setUsers({});
            setPaginationTokens({});
          }

          getUsersList();
          setSnackbarOptions({
            open: true,
            message: 'Usuaria actualizada correctamente',
            type: 'success',
          });
        }}
        onError={() =>
          setSnackbarOptions({
            open: true,
            message: 'Error actualizando la usuaria',
            type: 'error',
          })
        }
      />
      <CustomSnackbar options={snackbarOptions} setOptions={setSnackbarOptions} />
      <AlertDialog options={dialogOptions} setOptions={setDialogOptions} />

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {permissions.edit && (
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              setShowEditModal(true);
            }}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Editar
          </MenuItem>
        )}

        {permissions.delete && (
          <MenuItem
            sx={{ color: 'error.main' }}
            onClick={() => {
              handleCloseMenu();
              setDialogOptions({
                open: true,
                title: '¿Está seguro que desea eliminar esta usuaria?',
                message:
                  'Esta es una acción irreversible, ningún tipo de información relacionada con esta usuaria podrá recuperarse después.',
                showCancelButton: true,
                cancelButtonFocused: true,
                onAcceptClick: deleteUserHandler,
                onCancelClick: null,
              });
            }}
          >
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Eliminar
          </MenuItem>
        )}
      </Popover>
    </>
  );

  function CustomTableRow({ row }) {
    const { id, name, email, phone_number, created_at, status } = row;
    const selectedUser = selected.indexOf(id) !== -1;
    return (
      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
        <TableCell padding="checkbox">
          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
        </TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">{email}</TableCell>
        <TableCell align="left">{phone_number}</TableCell>
        <TableCell align="left">{id}</TableCell>
        <TableCell align="left">{fDate(created_at, 'dd/MM/yy')}</TableCell>
        <TableCell align="left">
          <Label color={status !== 'true' ? 'error' : 'success'}>{getStatus(status)}</Label>
        </TableCell>
        <TableCell align="right">
          {(permissions.edit || permissions.delete) && (
            <IconButton
              size="large"
              color="inherit"
              onClick={(e) => {
                handleOpenMenu(e, row);
              }}
            >
              <Iconify icon={'eva:more-vertical-fill'} />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  }
}
