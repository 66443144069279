import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function getRelativeTime(date_) {
  const now = new Date();
  const date = new Date(date_);
  const diff = now - date;
  let time = '';
  if (diff <= 0 || diff < 60000) {
    // less than 1 minute
    time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  } else if (diff < 3600000) {
    // less than 1 hour
    time = `${Math.floor(diff / 60000)} minutes ago`;
  } else if (diff < 86400000) {
    // less than 1 day
    time = `${Math.floor(diff / 3600000)} hours ago`;
  } else if (diff < 604800000) {
    // less than 1 week
    time = diff < 86400000 * 2 ?
      `Hace ${Math.floor(diff / 3600000)} hora${Math.floor(diff / 3600000) > 1 ? 's' : ''} atrás` :
      `Hace ${Math.floor(diff / 86400000)} día${Math.floor(diff / 86400000) > 1 ? 's' : ''} atrás`;
  } else {
    // more than 1 week
    time = diff < 86400000 * 7 * 2 ?
      `Hace ${Math.floor(diff / 86400000)} día${Math.floor(diff / 86400000) > 1 ? 's' : ''} atrás` :
      `Hace ${Math.floor(diff / 86400000 / 7)} semana${Math.floor(diff / 86400000 / 7) > 1 ? 's' : ''} atrás`;
  }
  return time;
}
