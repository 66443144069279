import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { TextField } from '@mui/material';

const NumericFormatCustom = forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$ "
    />
  );
});

const CurrencyField = ({ value, onChange, name, label, style }) => (
  <TextField
    value={value}
    onChange={onChange}
    name={name}
    label={label}
    id="formatted-numberformat-input"
    sx={style}
    InputProps={{
      inputComponent: NumericFormatCustom,
    }}
    variant="standard"
  />
);

export default CurrencyField;
