import { initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';

const firebaseConfig =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? {
        apiKey: process.env.REACT_APP_PROD_FB_apiKey,
        authDomain: process.env.REACT_APP_PROD_FB_authDomain,
        projectId: process.env.REACT_APP_PROD_FB_projectId,
        storageBucket: process.env.REACT_APP_PROD_FB_storageBucket,
        messagingSenderId: process.env.REACT_APP_PROD_FB_messagingSenderId,
        appId: process.env.REACT_APP_PROD_FB_appId,
        measurementId: process.env.REACT_APP_PROD_FB_measurementId,
      }
    : {
        apiKey: process.env.REACT_APP_DEV_FB_apiKey,
        authDomain: process.env.REACT_APP_DEV_FB_authDomain,
        projectId: process.env.REACT_APP_DEV_FB_projectId,
        storageBucket: process.env.REACT_APP_DEV_FB_storageBucket,
        messagingSenderId: process.env.REACT_APP_DEV_FB_messagingSenderId,
        appId: process.env.REACT_APP_DEV_FB_appId,
        measurementId: process.env.REACT_APP_DEV_FB_measurementId,
      };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app)
