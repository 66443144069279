/* eslint-disable camelcase */
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container, CircularProgress, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import PropTypes from 'prop-types';
import Iconify from '../../../components/iconify/Iconify';

import { getDriver, deleteDriver, approveDriver } from '../../../actions/driversActions';
import AlertDialog from '../../../components/ui/Dialogs';
import { CustomSnackbar } from '../../../components/ui/Snackbars';
import { validateTokenError } from '../../../actions/auth';
import EditDriverCard from './editDriverCard';
import { getPermissions, usePagePermissionCheck } from '../../../permissions';
import DriverInfoCard from './driverInfoCard';
import DriverDocumentsCard from './driverDocumentsCard';
import VehicleCard from './vehicleCard';
import FloatingButton from '../../../components/ui/FloatingButton';
import { checkIfDocumentsAreComplete } from '../../../actions/documentsActions';
import WalletCard from './walletCard';

DriverDetails.propTypes = {
  username: PropTypes.string,
};

function getStatusLabel(driver) {
  const { groups } = driver;
  let label = null;
  if (driver.groups && groups.includes('driver')) {
    label = (
      <Typography component="h1" variant="h4" sx={{ color: '#93278f', marginRight: 1 }}>
        Aprobada
      </Typography>
    );
  } else if (driver.groups && groups.includes('driver_request')) {
    label = (
      <Typography component="h1" variant="h4" sx={{ color: '#FFC107', marginRight: 1 }}>
        Pendiente
      </Typography>
    );
  } else if (driver.groups && groups.includes('newborn_driver')) {
    label = (
      <Typography component="h1" variant="h4" sx={{ color: '#1890FF', marginRight: 1 }}>
        Sin solicitud
      </Typography>
    );
  }
  return label;
}

export default function DriverDetails({ username }) {
  usePagePermissionCheck('drivers');
  const navigate = useNavigate();

  const [driver, setDriver] = useState({});
  const [avalaibleToApprove, setAvalaibleToApprove] = useState(false);

  const [permissions, setPermissions] = useState({
    read: false,
    write: false,
    edit: false,
    delete: false,
  });

  const [loading, setLoading] = useState(false);

  const [snackbarOptions, setSnackbarOptions] = useState({
    open: false,
    message: '',
    type: '',
  });
  const [dialogOptions, setDialogOptions] = useState({
    open: false,
    title: null,
    message: null,
    showCancelButton: false,
    cancelButtonFocused: false,
    onAcceptClick: null,
    onCancelClick: null,
  });

  const getDriverDetails = () => {
    getDriver(username)
      .then((data) => {
        setDriver(data);
        setLoading(false);
      })
      .catch((error) => {
        validateTokenError(error);
        setSnackbarOptions({
          open: true,
          message: 'Error consultando las conductoras',
          type: 'error',
        });
        setLoading(false);
        console.error(error);
      });
  };

  // TODO: test this method
  const approveDriverRequest = () => {
    approveDriver(username)
      .then(({ data }) => {
        if (data.ok === true) {
          getDriverDetails();
          setSnackbarOptions({
            open: true,
            message: 'Conductora aprobada',
            type: 'success',
          });
        } else {
          setSnackbarOptions({
            open: true,
            message: data.message,
            type: 'error',
          });
        }
      })
      .catch((error) => {
        console.error(error);
        validateTokenError(error);
        const body = error.response.data;
        let message = '';
        if (body.error.includes('Vehicle not found for user')) {
          message = 'No hay ningún vehículo registrado';
        } else if (body.error.includes('No driver documentaton found')) {
          message = 'No se encontraron documentos';
        } else {
          message = body.error;
        }

        setSnackbarOptions({
          open: true,
          message,
          type: 'error',
        });
      });
  };

  const deleteDriverHandler = () => {
    deleteDriver(username)
      .then((response) => {
        const body = response.data;
        if (body.ok === true) {
          setSnackbarOptions({
            open: true,
            message: 'Conductora eliminada',
            type: 'success',
          });
          setDialogOptions({
            open: false,
            title: null,
            message: null,
            showCancelButton: false,
            cancelButtonFocused: false,
            onAcceptClick: null,
            onCancelClick: null,
          });
          navigate('/dashboard/conductoras');
        } else {
          setSnackbarOptions({
            open: true,
            message: 'Error eliminando la conductora',
            type: 'error',
          });
          console.error(response);
        }
      })
      .catch((error) => {
        validateTokenError(error);
        setSnackbarOptions({
          open: true,
          message: 'Error eliminando la conductora',
          type: 'error',
        });
        console.error(error);
      });
  };

  const checkIfAvalaibleToApprove = async () => {
    const completeDocuments = await checkIfDocumentsAreComplete(username);
    setAvalaibleToApprove(completeDocuments)
  };

  useEffect(() => {
    setPermissions(getPermissions('drivers'));
    setLoading(true);
    getDriverDetails();
    checkIfAvalaibleToApprove();
  }, []);

  return (
    <Container>
      <CustomSnackbar options={snackbarOptions} setOptions={setSnackbarOptions} />
      <AlertDialog options={dialogOptions} setOptions={setDialogOptions} />
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Link to="/dashboard/conductoras">
          <Button startIcon={<Iconify icon="oui:arrow-left" />} sx={{ marginBottom: 1 }}>
            Ver tabla
          </Button>
        </Link>
        {getStatusLabel(driver)}
      </Grid>

      {loading ? (
        <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          <DriverInfoCard driver={driver} cardStyle={{ padding: 3 }} />
          <EditDriverCard
            driver={driver}
            cardStyle={{ padding: '30px', marginTop: 3 }}
            onSuccess={() => {
              getDriverDetails();
              setSnackbarOptions({
                open: true,
                message: 'Conductora actualizada correctamente',
                type: 'success',
              });
            }}
            onError={() =>
              setSnackbarOptions({
                open: true,
                message: 'Error actualizando la conductora',
                type: 'error',
              })
            }
          />
          <VehicleCard vehicle={driver.vehicle} cardStyle={{ padding: '30px', marginTop: 3 }} />

          <DriverDocumentsCard username={driver.sub} cardStyle={{ padding: '30px', marginTop: 3 }} />

          <WalletCard username={driver.sub} cardStyle={{ padding: '30px', marginTop: 3 }} />

          {permissions.delete && (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Button
                color="error"
                startIcon={<Iconify icon="material-symbols:delete" />}
                sx={{ marginTop: 3, minWidth: '300px' }}
                onClick={() => {
                  setDialogOptions({
                    open: true,
                    title: '¿Está seguro que desea eliminar esta conductora?',
                    message:
                      'Esta es una acción irreversible, ningún tipo de información relacionada con esta conductora podrá recuperarse después.',
                    showCancelButton: true,
                    cancelButtonFocused: true,
                    onAcceptClick: deleteDriverHandler,
                    onCancelClick: null,
                  });
                }}
              >
                Eliminar conductora
              </Button>
            </Grid>
          )}
        </>
      )}
      {driver.groups && driver.groups.includes('driver_request') && avalaibleToApprove && driver.vehicle && (
        <FloatingButton text={'Aprobar conductora'} onClick={() => approveDriverRequest()} size="lg" />
      )}
    </Container>
  );
}
