import Cookies from 'js-cookie';
import clientAxios from '../config/axios';

const getDrivers = async (group, paginationToken, limit) => {
  const token = Cookies.get('token');
  const response = await clientAxios.get(`/users?group=${group || 'driver'}&limit=${limit}`, {
    headers: {
      authorization: `Bearer ${token}`,
      userspgtoken: paginationToken || undefined,
    },
  });
  return response.data;
};

const getDriversCount = async (group) => {
  const token = Cookies.get('token');
  let count = 0;
  const response = await clientAxios.get(`/users/count?group=${group || 'driver'}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  const body = response.data;
  if (body.ok === true) {
    count = parseInt(body.message, 10);
  }
  return count;
};

const getDriver = async (username) => {
  const token = Cookies.get('token');
  const response = await clientAxios.get(`/users/drivers?username=${username}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  const body = response.data;
  if (body.ok === true) {
    return body.data;
  }
  return response;
};

const editDriver = async (user) => {
  const token = Cookies.get('token');
  const response = await clientAxios.put(
    '/users',
    {
      Username: user.id,
      user_name: user.name,
      email: user.email,
      phone_number: user.phone_number,
      'custom:dni': user.dni,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
  return response;
};

const approveDriver = async (username) => {
  const token = Cookies.get('token');
  const response = await clientAxios.post(
    `/users/drivers/approve_request?username=${username}`,
    {},
    {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
  return response;
};

const deleteDriver = async (username) => {
  const token = Cookies.get('token');
  const response = await clientAxios.delete(`/users?username=${username}`, {
    headers: {
      authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export { getDrivers, getDriver, getDriversCount, editDriver, deleteDriver, approveDriver };
