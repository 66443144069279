import Cookies from 'js-cookie';
import clientAxios from '../config/axios';

const getUsers = async (paginationToken, limit) => {
  const token = Cookies.get('token');
  const response = await clientAxios.get(`/users?group=passenger&limit=${limit}`, {
    headers: {
      authorization: `Bearer ${token}`,
      userspgtoken: paginationToken || undefined,
    },
  });
  return response.data;
};

const getUsersCount = async () => {
  const token = Cookies.get('token');
  let count = 0;
  const response = await clientAxios.get(`/users/count?group=passenger`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  const body = response.data;
  if (body.ok === true) {
    count = parseInt(body.message, 10);
  }
  return count;
};

const editUser = async (user) => {
  const token = Cookies.get('token');
  const response = await clientAxios.put(
    '/users',
    {
      Username: user.id,
      user_name: user.name,
      email: user.email,
      phone_number: user.phone_number,
      'custom:dni': user.dni,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
  return response;
};

const deleteUser = async (username) => {
  const token = Cookies.get('token');
  const response = await clientAxios.delete(`/users?username=${username}`, {
    headers: {
      authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export { getUsers, getUsersCount, editUser, deleteUser };
