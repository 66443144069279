/* eslint-disable camelcase */
import * as React from 'react';
import { useState, useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { MapInteractionCSS } from 'react-map-interaction';

import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Container,
  Grid,
  Modal,
  Fade,
  Backdrop,
  Typography,
  TextField,
  Drawer,
  Alert,
} from '@mui/material';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import MotionPhotosOffOutlinedIcon from '@mui/icons-material/MotionPhotosOffOutlined';

import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { getPermissions } from '../../permissions';
import Iconify from '../iconify/Iconify';
import { updateDocument } from '../../actions/documentsActions';

import { useMediaContext } from '../../state/media';
import { validateTokenError } from '../../actions/auth';

const CloseIconStyle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  color: 'grey',
  ':hover': { color: 'black' },
}));

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '80vw',
  maxHeight: '90vh',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
};
const helperButton = {
  color: 'grey',
  fontWeight: 500,
  border: '1px solid #C4CDD5',
  padding: '0 5px',
  textTransform: 'none',
};

const DocumentViewer = ({ document, username, setOpen, onSuccess, onError }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [comments, setComments] = useState();
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState({
    read: false,
    write: false,
    edit: false,
    delete: false,
  });
  const updateDocumentStatus = (status) => {
    setLoading(true);
    updateDocument(username, {
      Key: document.key,
      status,
      comments: comments || '',
    })
      .then(() => {
        if (onSuccess) onSuccess();
        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        console.error(error);
        validateTokenError(error);
        if (onError) onError();
        setLoading(false);
      });
  };

  const _handleTextFieldChange = (e) => {
    setComments(e.target.value);
  };

  useEffect(() => {
    setPermissions(getPermissions('documents'));
  }, []);

  return (
    <Container component="main">
      <Grid container>
        <Grid item xs={12} sm={8} md={9}>
          <Typography component="h1" variant="h5">
            {document.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Grid container gap={0.5} direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: 0.5 }}>
            {getStatusLabel(document.status)}
          </Grid>
        </Grid>
      </Grid>

      <Grid container columnSpacing={4} rowSpacing={1} sx={{ marginTop: 1, marginBottom: 1 }}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center', alignContent: 'center' }}
        >
          <div style={{ display: 'inline-block' }}>
            <MapInteractionCSS
              minScale={0.5}
              maxScale={3}
              translationBounds={{
                xMax: 400,
                yMax: 200,
              }}
            >
              <img src={document.url} alt={document.name} style={{ maxHeight: '70vh' }} />
            </MapInteractionCSS>
          </div>
        </Grid>
        {(permissions.edit || permissions.write) && (
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              spacing={2}
              sx={{ height: '100%', marginTop: 0.1 }}
            >
              <div>
                {document.comments && document.comments !== 'uploaded' && (
                  <>
                    <Typography variant="subtitle1">Comentarios</Typography>
                    <Box
                      mb={2}
                      display="flex"
                      flexDirection="column"
                      maxHeight="200px"
                      style={{
                        overflow: 'hidden',
                        overflowY: 'scroll',
                      }}
                    >
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {document.comments}
                      </Typography>
                    </Box>
                  </>
                )}
                <Typography variant="subtitle1">Nuevo comentario</Typography>

                <TextField
                  fullWidth
                  id="comments"
                  name="comments"
                  multiline
                  onChange={_handleTextFieldChange}
                  value={comments}
                  inputProps={{ style: { fontSize: 14, lineHeight: 1.2 } }} // font size of input text
                />
                <div style={{ marginTop: 10, rowGap: 10 }}>
                  <Button
                    size="small"
                    color="info"
                    sx={helperButton}
                    onClick={() => setComments('No es posible visualizar el documento')}
                  >
                    No es posible visualizar el documento
                  </Button>

                  <Button
                    size="small"
                    color="info"
                    sx={helperButton}
                    onClick={() => setComments('La información no se ve con claridad')}
                  >
                    La información no se ve con claridad
                  </Button>
                  <Button
                    size="small"
                    color="info"
                    sx={helperButton}
                    onClick={() => setComments('El documento no corresponde al solicitado')}
                  >
                    El documento no corresponde al solicitado
                  </Button>
                </div>
              </div>

              <Grid container spacing={2} sx={{ marginTop: 1 }}>
                {showErrorMessage && (
                  <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: '100%' }}>
                    <Alert severity="error" sx={{ bgcolor: 'background.paper', color: 'red' }}>
                      {errorMessage}
                    </Alert>
                  </div>
                )}
                {loading && (
                  <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                    <CircularProgress color="primary" />
                  </div>
                )}
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="error"
                    startIcon={<Iconify icon="mdi:check-bold" />}
                    onClick={() => {
                      if (!comments) {
                        setErrorMessage('Debe poner un comentario');
                        setShowErrorMessage(true);
                      } else {
                        updateDocumentStatus('denied');
                      }
                    }}
                    disabled={loading}
                  >
                    Rechazar
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="success"
                    startIcon={<Iconify icon="mdi:check-bold" />}
                    onClick={() => {
                      updateDocumentStatus('approved');
                    }}
                    disabled={loading}
                  >
                    Aprobar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default function ShowDocumentModal({ open, setOpen, document, username, onSuccess, onError }) {
  const { isSmallScreen } = useMediaContext();
  const handleClose = () => {
    setOpen(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return isSmallScreen ? (
    <Drawer anchor="bottom" open={open} onClose={toggleDrawer(false)}>
      <Box p={2}>
        <CloseIconStyle>
          <CloseIcon onClick={toggleDrawer(false)} className="test" />
        </CloseIconStyle>
        <DocumentViewer
          document={document}
          username={username}
          setOpen={setOpen}
          onSuccess={onSuccess}
          onError={onError}
        />
      </Box>
    </Drawer>
  ) : (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      sx={{ overflow: 'scroll' }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={boxStyle}>
          <CloseIconStyle>
            <CloseIcon onClick={toggleDrawer(false)} className="test" />
          </CloseIconStyle>
          <DocumentViewer
            document={document}
            username={username}
            setOpen={setOpen}
            onSuccess={onSuccess}
            onError={onError}
          />
        </Box>
      </Fade>
    </Modal>
  );
}

const getStatusLabel = (status) => {
  switch (status) {
    case 'review':
      return (
        <>
          <AccessTimeIcon color="warning" fontSize="small" />
          <Typography variant="subtitle1" sx={{ color: '#FFC107' }}>
            Revisión
          </Typography>
        </>
      );

    case 'approved':
      return (
        <>
          <TaskAltIcon color="success" fontSize="small" />
          <Typography variant="subtitle1" sx={{ color: '#60D03E' }}>
            Aprobado
          </Typography>
        </>
      );

    case 'denied':
      return (
        <>
          <CancelOutlinedIcon color="error" fontSize="small" />
          <Typography variant="subtitle1" sx={{ color: '#FF4842' }}>
            Rechazado
          </Typography>
        </>
      );

    default:
      return (
        <>
          <MotionPhotosOffOutlinedIcon fontSize="small" sx={{ color: 'grey' }} />
          <Typography variant="subtitle1" sx={{ color: 'grey' }}>
            No info
          </Typography>
        </>
      );
  }
};

DocumentViewer.propTypes = {
  document: PropTypes.shape({
    key: PropTypes.string,
    comments: PropTypes.string,
    status: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  username: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  setOpen: PropTypes.func,
};

ShowDocumentModal.propTypes = {
  document: PropTypes.shape({
    key: PropTypes.string,
    comments: PropTypes.string,
    status: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  username: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
