import { Card, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import PropTypes from 'prop-types';
import Iconify from '../../../components/iconify/Iconify';

VehicleCard.propTypes = {
  vehicle: PropTypes.shape({
    vehicle_type: PropTypes.oneOf(['CAR', 'MOTO']),
    license_plate: PropTypes.string,
    year: PropTypes.string,
    brand: PropTypes.string,
    model: PropTypes.string,
  }),
  cardStyle: PropTypes.object,
};

export default function VehicleCard({ vehicle, cardStyle }) {
  return (
    <Card sx={cardStyle}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Vehículo</Typography>
      </Grid>
      {vehicle ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={{ xs: 2, sm: 2, md: 3 }}
        >
          <Grid container direction="column" justifyContent="center" alignItems="center" xs={12} sm={4}>
            {vehicle?.vehicle_type === 'CAR' ? (
              <img src="/assets/images/vehicle.png" alt="carro" width="250px" style={{ padding: 20 }} />
            ) : (
              <img src="/assets/images/moto.png" alt="moto" height="170px" style={{ padding: 20 }} />
            )}
          </Grid>

          <Grid item="true" xs={6} sm={2}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Iconify icon="solar:plate-bold-duotone" />
              <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                Placa
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                {vehicle.license_plate}
              </Typography>
            </Grid>
          </Grid>

          <Grid item="true" xs={6} sm={2}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Iconify icon="mdi:clock-outline" />
              <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                Año
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                {vehicle.year}
              </Typography>
            </Grid>
          </Grid>
          <Grid item="true" xs={6} sm={2}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Iconify icon="simple-icons:renault" />

              <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                Marca
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                {vehicle.brand}
              </Typography>
            </Grid>
          </Grid>

          <Grid item="true" xs={6} sm={2}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Iconify icon="material-symbols-light:car-tag-rounded" />
              <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                Modelo
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary', textAlign:'center', lineHeight: 1.2 }}>
                {vehicle.model}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Typography variant="h5" sx={{ color: 'text.secondary' }}>
          No hay vehículos registrados
        </Typography>
      )}
    </Card>
  );
}
