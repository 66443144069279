import { faker } from '@faker-js/faker';


// ----------------------------------------------------------------------

const carros = [...Array(24)].map(() => ({
  id: faker.datatype.uuid(),
  cedula: faker.random.numeric(5),
  imagen: faker.image.technics(),
  modelo: faker.vehicle.model(),
  tipovehiculo: faker.vehicle.type(),
  conductor: faker.name.fullName(),
  placa: faker.random.alphaNumeric(6),
}));

export default carros;
