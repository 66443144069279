import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// cookies
import Cookies from 'js-cookie';
// @mui
import {  Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { login } from '../../../actions/auth';
import Iconify from '../../../components/iconify';
// import { useHistory } from "react-router-dom";
import ErrorMessage from '../../../components/ui/ErrorMessage';

// ----------------------------------------------------------------------

const LoginForm = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({
    username: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const _handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const validateFields = () => {
    const phoneValidation = /^3[0-9]{9}$/;
    const passwordValidation =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$#@$!%*?&-/+])([A-Za-z\d$#@$!%*?&-/+]|[^ ]){8,15}$/;

    if (user.username === '' || user.password === '') {
      setErrorMessage('Debe llenar todos los campos correctamente');
      setShowErrorMessage(true);
      return false;
    }

    if (!phoneValidation.test(user.username)) {
      setErrorMessage('Ingrese un celular válido');
      setShowErrorMessage(true);
      return false;
    }
    if (!passwordValidation.test(user.password)) {
      setErrorMessage('Ingrese una contraseña válida');
      setShowErrorMessage(true);
      return false;
    }

    return true;
  };

  const manejadorLogin = async () => {
    if (!validateFields()) return;
    login(user).then((body) => {
      if (body.ok === true) {
        Cookies.set('token', body.token);
        Cookies.set('username', body.username);
        navigate('/dashboard/app', { replace: true });
      } else {
        setErrorMessage('Algo salió mal');
        setShowErrorMessage(true);
      }
    }).catch((error)=>{
      const body = error.response.data;
      if (body.error === 'NotAuthorizedException') {
        setErrorMessage('Usuario o contraseña incorrecta');
      } else if (body.error === 'InvalidParameterException') {
        setErrorMessage('Debe llenar todos los campos correctamente');
      } else if (body.code === 'UserNotFoundException') {
        setErrorMessage('El usuario no existe');
      } else if (body.error === 'UserNotConfirmedException' || body.details.UserStatus === 'UNCONFIRMED') {
        setErrorMessage('Usuario no confirmado');
      } else {
        setErrorMessage(body.error || body.message);
      }
      setShowErrorMessage(true);
    });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="username" label="Celular" value={user.username} onChange={_handleChange} />

        <TextField
          name="password"
          label="Contraseña"
          value={user.password}
          onChange={_handleChange}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <br />
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={manejadorLogin}>
        Ingresar
      </LoadingButton>
      {showErrorMessage && <ErrorMessage message={errorMessage} />}
    </>
  );
};

export default LoginForm;
