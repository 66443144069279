/* eslint-disable camelcase */
import { Button, Card, CircularProgress, Typography, TextField, IconButton, Alert, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import PropTypes from 'prop-types';
import Iconify from '../../../components/iconify/Iconify';

import { editDriver } from '../../../actions/driversActions';
import { validateTokenError } from '../../../actions/auth';
import { getPermissions } from '../../../permissions';

EditDriverCard.propTypes = {
  driver: PropTypes.shape({
    profile_picture: PropTypes.string,
    sub: PropTypes.string,
    name: PropTypes.string,
    phone_number: PropTypes.string,
    email: PropTypes.string,
    'custom:dni': PropTypes.string,
    rating: PropTypes.number,
    memberSinceDate: PropTypes.string,
    rideCount: PropTypes.number,
  }).isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  cardStyle: PropTypes.object,
};

export default function EditDriverCard({ driver, onSuccess, onError, cardStyle }) {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [permissions, setPermissions] = useState({
    read: false,
    write: false,
    edit: false,
    delete: false,
  });

  const validateFields = (userData) => {
    const { name, email, phone_number, dni } = userData;

    const emailRegex = /\S+@\S+\.\S+/;
    const phoneRegex = /^\+[0-9]{12}$/;
    const dniRegex = /^((\d{8})|(\d{10})|(\d{11})|(\d{6}-\d{5}))?$/;

    if (driver.name === name && driver.email === email && driver.phone_number === phone_number && driver.dni === dni) {
      return false;
    }
    if (name === '' || email === '' || phone_number === '') {
      setErrorMessage('Todos los campos son obligatorios');
      setShowErrorMessage(true);
      return false;
    }
    if (!emailRegex.test(email)) {
      setErrorMessage('Ingrese un correo válido');
      setShowErrorMessage(true);
      return false;
    }
    if (!phoneRegex.test(phone_number)) {
      setErrorMessage('Ingrese un celular válido');
      setShowErrorMessage(true);
      return false;
    }
    if (!dniRegex.test(dni)) {
      setErrorMessage('Ingrese un cédula válida');
      setShowErrorMessage(true);
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (editMode) {
      const data = JSON.stringify(Object.fromEntries(new FormData(event.currentTarget)));
      const userData = JSON.parse(data);
      userData.id = driver.sub;
      if (validateFields(userData)) {
        setLoading(true);
        editDriver(userData)
          .then(() => {
            if (onSuccess) onSuccess();
            setLoading(false);
            setEditMode(false);
            setShowErrorMessage(false);
          })
          .catch((error) => {
            console.error(error);
            validateTokenError(error);
            if (onError) onError();
            setLoading(false);
          });
      }
    } else {
      setEditMode(true);
    }
  };

  useEffect(() => {
    setPermissions(getPermissions('drivers'));
  }, []);

  return (
    <Card sx={cardStyle}>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Información general</Typography>
          {permissions.edit && (
            <>
              {editMode ? (
                <IconButton
                  onClick={() => {
                    setEditMode(false);
                  }}
                >
                  <Iconify icon={'material-symbols:close'} />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  <Iconify icon={'eva:edit-fill'} />
                </IconButton>
              )}
            </>
          )}
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item="true" xs={12} sm={6}>
            <TextField
              required
              fullWidth
              disabled={!editMode}
              id="name"
              label="Nombre"
              name="name"
              defaultValue={driver?.name || ''}
            />
          </Grid>
          <Grid item="true" xs={12} sm={6}>
            <TextField
              required
              fullWidth
              disabled={!editMode}
              id="email"
              label="Correo"
              name="email"
              defaultValue={driver?.email || ''}
            />
          </Grid>
          <Grid item="true" xs={12} sm={6}>
            <TextField
              required
              fullWidth
              disabled={!editMode}
              id="phone_number"
              label="Celular"
              name="phone_number"
              defaultValue={driver?.phone_number || ''}
            />
          </Grid>
          <Grid item="true" xs={12} sm={6}>
            <TextField
              fullWidth
              disabled={!editMode}
              id="dni"
              label="Cédula"
              name="dni"
              defaultValue={driver?.['custom:dni'] || ''}
            />
          </Grid>
          {showErrorMessage && (
            <Grid item="true" xs={12}>
              <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <Alert severity="error" sx={{ bgcolor: 'background.paper', color: 'red' }}>
                  {errorMessage}
                </Alert>
              </div>
            </Grid>
          )}
          {permissions.edit && editMode && (
            <Grid item="true" xs={12} sm={6} md={3} smOffset={6} mdOffset={9}>
              <Button type="submit" fullWidth variant="contained">
                {loading ? <CircularProgress color="inherit" sx={{ p: 1 }} /> : 'Guardar cambios'}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Card>
  );
}
