import Iconify from '../iconify';

const ErrorMessage = ({ message }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      color: 'red',
      margin: '20px 0',
    }}
  >
    <Iconify icon="jam:alert" />
    &nbsp;
    {message}
  </div>
);

export default ErrorMessage;
