import { Helmet } from 'react-helmet-async';
import { Card, Stack, Button, Container, Typography, IconButton, CircularProgress, TextField } from '@mui/material';

import { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { getDocs, doc, collection, query, updateDoc, getDoc } from '@firebase/firestore';

import CurrencyField from 'components/ui/CurrencyField';
import { CustomSnackbar } from 'components/ui/Snackbars';

import { useTheme } from '@mui/material/styles';
import FareExplorer from 'components/fares/FareExplorer';
import Iconify from '../../components/iconify/Iconify';
import { firestore } from '../../config/firebase';
import { getPermissions } from '../../permissions';

export default function Fares() {
  const theme = useTheme();

  const cardStyle = {
    padding: '20px 20px 20px 30px',
    [theme.breakpoints.up('sm')]: {
      minHeight: '170px',
    },
  };

  const [fares, setFares] = useState({});
  const [loading, setLoading] = useState(false);
  const [carFares, setCarFares] = useState({});
  const [motoFares, setMotoFares] = useState({});
  const [editCarMode, setEditCarMode] = useState(false);
  const [editMotoMode, setEditMotoMode] = useState(false);
  const [editFeeMode, setEdiFeeMode] = useState(false);
  const [serviceFee, setServiceFee] = useState(0);
  const [tempServiceFee, setTempServiceFee] = useState();

  const [permissions, setPermissions] = useState({
    read: false,
    write: false,
    edit: false,
    delete: false,
  });
  const [snackbarOptions, setSnackbarOptions] = useState({
    open: false,
    message: '',
    type: '',
  });

  const ref = collection(firestore, 'prices');
  const feeRef = doc(firestore, 'Fees', 'service_fee');

  const editCarFares = async () => {
    let invalidValues = false;

    Object.keys(carFares).forEach((key) => {
      if (Number.isNaN(carFares[key])) {
        invalidValues = true;
      }
    });

    if (invalidValues) {
      setSnackbarOptions({
        open: true,
        message: 'Ingrese valores válidos',
        type: 'error',
      });
      return;
    }

    updateDoc(doc(ref, 'CAR'), carFares)
      .then(() => {
        setCarFares({});
        setEditCarMode(false);
        setSnackbarOptions({
          open: true,
          message: 'Se actualizó la tarifa correctamente',
          type: 'success',
        });
        getInfo();
      })
      .catch((error) => {
        setSnackbarOptions({
          open: true,
          message: 'Error editando las tarifas',
          type: 'error',
        });
        console.error(`Unsuccessful editCarFares ${error}`);
      });
  };

  const editMotoFares = async () => {
    let invalidValues = false;

    Object.keys(motoFares).forEach((key) => {
      if (Number.isNaN(motoFares[key])) {
        invalidValues = true;
      }
    });

    if (invalidValues) {
      setSnackbarOptions({
        open: true,
        message: 'Ingrese valores válidos',
        type: 'error',
      });
      return;
    }

    updateDoc(doc(ref, 'MOTO'), motoFares)
      .then(() => {
        setMotoFares({});
        setEditMotoMode(false);
        setSnackbarOptions({
          open: true,
          message: 'Se actualizó la tarifa correctamente',
          type: 'success',
        });
        getInfo();
      })
      .catch((error) => {
        setSnackbarOptions({
          open: true,
          message: 'Error editando las tarifas',
          type: 'error',
        });
        console.error(`Unsuccessful editMotoFares ${error}`);
      });
  };

  const editServiceFee = async () => {
    if (!tempServiceFee || tempServiceFee < 0 || tempServiceFee > 100 || Number.isNaN(tempServiceFee)) {
      setSnackbarOptions({
        open: true,
        message: 'Ingrese un porcentaje válido',
        type: 'error',
      });
      return;
    }

    updateDoc(feeRef, { percentage: tempServiceFee })
      .then(() => {
        setEdiFeeMode(false);
        setSnackbarOptions({
          open: true,
          message: 'Se actualizó la comisión correctamente',
          type: 'success',
        });
        setTempServiceFee();
        getServiceFee();
      })
      .catch((error) => {
        setSnackbarOptions({
          open: true,
          message: 'Error actualizando la comisión',
          type: 'error',
        });
        console.error(`Unsuccessful editServiceFee ${error}`);
      });
  };

  const getInfo = async () => {
    setLoading(true);
    getServiceFee();
    const q = query(ref);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setFares((prevState) => ({
        ...prevState,
        [doc.id]: doc.data(),
      }));
    });
    setLoading(false);
  };

  const getServiceFee = async () => {
    const balanceSnap = await getDoc(feeRef);
    if (balanceSnap.exists()) {
      setServiceFee(balanceSnap.data().percentage);
    }
  };

  const handleChangeCar = (event) => {
    setCarFares({
      ...carFares,
      [event.target.name]: parseInt(event.target.value, 10),
    });
  };

  const handleChangeMoto = (event) => {
    setMotoFares({
      ...motoFares,
      [event.target.name]: parseInt(event.target.value, 10),
    });
  };

  useEffect(() => {
    setPermissions(getPermissions('fares'));
    getInfo();
  }, []);

  return (
    <>
      <CustomSnackbar options={snackbarOptions} setOptions={setSnackbarOptions} />

      <Helmet>
        <title> Tarifas | Luguu </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Tarifas
          </Typography>
        </Stack>
        {loading ? (
          <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Card sx={{ padding: '30px' }}>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={7.5}>
                    <Typography variant="h5">Comisión de Luguu</Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      Porcentaje de ganancia por servicio{' '}
                    </Typography>
                  </Grid>
                  <Grid xs={10} sm={4} alignItems="center" display="flex" justifyContent={'end'}>
                    {editFeeMode ? (
                      <TextField
                        id="standard-number"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={tempServiceFee}
                        onChange={(event) => {
                          setTempServiceFee(parseFloat(event.target.value, 10));
                        }}
                        InputProps={{ style: { fontSize: '30px', fontWeight: 600, width: 55 } }}
                        variant="standard"
                      />
                    ) : (
                      <Typography variant="h2" sx={{ margin: 0 }}>
                        {serviceFee}
                      </Typography>
                    )}
                    <Typography variant="h2" sx={{ margin: 0 }}>
                      %
                    </Typography>
                  </Grid>
                  <Grid xs={1} sm={0.5}>
                    {permissions.edit && (
                      <>
                        {editFeeMode ? (
                          <>
                            <IconButton
                              onClick={() => {
                                setEdiFeeMode(false);
                                setTempServiceFee();
                              }}
                            >
                              <Iconify icon={'material-symbols:close'} />
                            </IconButton>
                            <IconButton
                              color="primary"
                              onClick={() => {
                                editServiceFee();
                              }}
                            >
                              <Iconify icon={'mdi:check-bold'} />
                            </IconButton>
                          </>
                        ) : (
                          <IconButton
                            onClick={() => {
                              setEdiFeeMode(true);
                            }}
                          >
                            <Iconify icon={'eva:edit-fill'} />
                          </IconButton>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid xs={12} sm={6}>
              {fares.CAR && (
                <Card sx={cardStyle}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ marginBottom: 1 }}
                  >
                    <Typography variant="h5">Carro</Typography>
                    {permissions.edit && (
                      <>
                        {editCarMode ? (
                          <IconButton
                            onClick={() => {
                              setCarFares({});
                              setEditCarMode(false);
                            }}
                          >
                            <Iconify icon={'material-symbols:close'} />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => {
                              setEditCarMode(true);
                            }}
                          >
                            <Iconify icon={'eva:edit-fill'} />
                          </IconButton>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid container direction="column" sx={{ paddingRight: '10px', marginBottom: '10px' }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      rowSpacing={3}
                      columnGap={3}
                    >
                      <Grid xs={12} sm={4} justifyContent="center" alignItems="center" display="flex">
                        <img src="/assets/images/vehicle.png" alt="carro" style={{ maxWidth: '150px' }} />
                      </Grid>
                      <Grid>
                        <Grid container gap={1} direction="row" alignItems="center">
                          <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                            Kilómetro:
                          </Typography>
                          {editCarMode ? (
                            <CurrencyField
                              value={fares.CAR.km}
                              onChange={handleChangeCar}
                              name="km"
                              style={{ width: '80px' }}
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              sx={{ color: 'text.secondary', textAlign: 'center', lineHeight: 1.2 }}
                            >
                              {Intl.NumberFormat('en-DE').format(fares.CAR.km || '0')}
                            </Typography>
                          )}
                        </Grid>
                        <Grid container gap={1} direction="row" alignItems="center">
                          <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                            Minuto:
                          </Typography>
                          {editCarMode ? (
                            <CurrencyField
                              value={fares.CAR.min}
                              onChange={handleChangeCar}
                              name="min"
                              style={{ width: '80px' }}
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              sx={{ color: 'text.secondary', textAlign: 'center', lineHeight: 1.2 }}
                            >
                              {Intl.NumberFormat('en-DE').format(fares.CAR.min || '0')}
                            </Typography>
                          )}
                        </Grid>
                        <Grid container gap={1} direction="row" alignItems="center">
                          <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                            Mínima:
                          </Typography>
                          {editCarMode ? (
                            <CurrencyField
                              value={fares.CAR.minValue}
                              onChange={handleChangeCar}
                              name="minValue"
                              style={{ width: '80px' }}
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              sx={{ color: 'text.secondary', textAlign: 'center', lineHeight: 1.2 }}
                            >
                              {Intl.NumberFormat('en-DE').format(fares.CAR.minValue || '0')}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {permissions.edit && editCarMode && (
                    <Grid item="true" xs={12} sm={6} md={3} smOffset={6} mdOffset={9}>
                      <Button type="submit" fullWidth variant="contained" onClick={editCarFares}>
                        Guardar
                      </Button>
                    </Grid>
                  )}
                </Card>
              )}
            </Grid>
            <Grid xs={12} sm={6}>
              {fares.MOTO && (
                <Card sx={cardStyle}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ marginBottom: 1 }}
                  >
                    <Typography variant="h5">Moto</Typography>
                    {permissions.edit && (
                      <>
                        {editMotoMode ? (
                          <IconButton
                            onClick={() => {
                              setMotoFares({});
                              setEditMotoMode(false);
                            }}
                          >
                            <Iconify icon={'material-symbols:close'} />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => {
                              setEditMotoMode(true);
                            }}
                          >
                            <Iconify icon={'eva:edit-fill'} />
                          </IconButton>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid container direction="column" sx={{ paddingRight: '10px', marginBottom: '10px' }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      rowSpacing={3}
                      columnGap={3}
                    >
                      <Grid
                        xs={12}
                        sm={4}
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        style={{ maxHeight: '100px' }}
                      >
                        <img src="/assets/images/moto.png" alt="moto" style={{ maxHeight: '80px' }} />
                      </Grid>
                      <Grid>
                        <Grid container gap={1} direction="row" alignItems="center">
                          <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                            Kilómetro:
                          </Typography>
                          {editMotoMode ? (
                            <CurrencyField
                              value={fares.MOTO.km}
                              onChange={handleChangeMoto}
                              name="km"
                              style={{ width: '80px' }}
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              sx={{ color: 'text.secondary', textAlign: 'center', lineHeight: 1.2 }}
                            >
                              {Intl.NumberFormat('en-DE').format(fares.MOTO.km || '0')}
                            </Typography>
                          )}
                        </Grid>
                        <Grid container gap={1} direction="row" alignItems="center">
                          <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                            Minuto:
                          </Typography>
                          {editMotoMode ? (
                            <CurrencyField
                              value={fares.MOTO.min}
                              onChange={handleChangeMoto}
                              name="min"
                              style={{ width: '80px' }}
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              sx={{ color: 'text.secondary', textAlign: 'center', lineHeight: 1.2 }}
                            >
                              {Intl.NumberFormat('en-DE').format(fares.MOTO.min || '0')}
                            </Typography>
                          )}
                        </Grid>
                        <Grid container gap={1} direction="row" alignItems="center">
                          <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                            Mínima:
                          </Typography>
                          {editMotoMode ? (
                            <CurrencyField
                              value={fares.MOTO.minValue}
                              onChange={handleChangeMoto}
                              name="minValue"
                              style={{ width: '80px' }}
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              sx={{ color: 'text.secondary', textAlign: 'center', lineHeight: 1.2 }}
                            >
                              {Intl.NumberFormat('en-DE').format(fares.MOTO.minValue || '0')}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {permissions.edit && editMotoMode && (
                    <Grid item="true" xs={12} sm={6} md={3} smOffset={6} mdOffset={9}>
                      <Button type="submit" fullWidth variant="contained" onClick={editMotoFares}>
                        Guardar
                      </Button>
                    </Grid>
                  )}
                </Card>
              )}
            </Grid>
            <Grid xs={12}>
              <FareExplorer fares={fares} fee={serviceFee} />
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
}
