import axios from 'axios';

const url =
  process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

const clientAxios = axios.create({
  baseURL: url,
});

export default clientAxios;
