import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import Iconify from '../components/iconify/Iconify';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function NoAccessPage() {
  return (
    <>
      <Helmet>
        <title> Luguu </title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Usted no tiene acceso a este módulo
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>Comuníquese con el administrador para solicitarlo</Typography>

          <Iconify
            icon="fxemoji:lock"
            sx={{
              width: { xs: 100, sm: 250 },
              height: { xs: 100, sm: 250 },
              my: { xs: 5, sm: 10 },
            }}
          />

          <Button to="/dashboard" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </StyledContent>
      </Container>
    </>
  );
}
