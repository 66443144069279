import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const DOCUMENT_NAME = [
  'Documento Legal',
  'Cedula Frontal',
  'Cedula Trasera',
  'Licencia Frontal',
  'Licencia Trasera',
  'Seguro SOAT',
  'Usuario con cedula',
];

const documentosImagenes = [...Array(7)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: faker.datatype.uuid(),
    cover: `/assets/images/documents/documento_${setIndex}.png`,
    name: DOCUMENT_NAME[index],
  };
});

export default documentosImagenes;
