import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { Container, Stack, Typography, Button,} from '@mui/material/';
import Iconify from '../../components/iconify/Iconify';
// components
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../../sections/@dashboard/products';
// mock
import DOCUMENTOS from '../../_mock/documentosImagenes';

// ----------------------------------------------------------------------

export default function DetalleDocumentos() {
  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  return (
    <>
      <Helmet>
        <title> Documentos Conductores | Luguu </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>


          <Link to="/dashboard/conductoras">
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:arrow-back-outline" />}
            >
                 Regresar
            </Button>
          </Link>
        </Typography>



        <ProductList products={DOCUMENTOS} />

      </Container>
    </>
  );
}
