// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { refreshToken } from './actions/auth';
import { MediaProvider } from './state/media';
// ----------------------------------------------------------------------

export default function App() {
  // Refresh token every 45 minutes
  refreshToken();
  setInterval(() => {
    refreshToken();
  }, 60 * 45 * 1000);

  return (
    <ThemeProvider>
      <MediaProvider>
        <ScrollToTop />
        <StyledChart />
        <Router />
      </MediaProvider>
    </ThemeProvider>
  );
}
