/* eslint-disable camelcase */
import { Button, Card, CircularProgress, Typography, IconButton, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import { getDoc, doc, collection, query, getDocs, orderBy } from '@firebase/firestore';
import { firestore } from 'config/firebase';
import { fDateTime } from 'utils/formatTime';
import ShowAddWalletMovement from 'components/modals/wallet/addWalletMovementModal';
import { CustomSnackbar } from 'components/ui/Snackbars';
import ShowHistoryModal from 'components/modals/wallet/showHistoryModal';
import Iconify from '../../../components/iconify/Iconify';
import { getPermissions } from '../../../permissions';

export default function WalletCard({ username, cardStyle }) {
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const [history, setHistory] = useState([]);
  const [openHistory, setOpenHistory] = useState(false);
  const [movementOptions, setMovementOptions] = useState({ open: false, type: '' });
  const [snackbarOptions, setSnackbarOptions] = useState({
    open: false,
    message: '',
    type: '',
  });

  const [permissions, setPermissions] = useState({
    read: false,
    write: false,
    edit: false,
    delete: false,
  });

  const getInfo = async () => {
    if (!username) {
      return;
    }
    setLoading(true);
    const balanceRef = doc(firestore, 'Wallet', username);
    const balanceSnap = await getDoc(balanceRef);
    if (balanceSnap.exists()) {
      setBalance(balanceSnap.data().balance);
    }

    const historRef = collection(firestore, 'Wallet', username, 'History');
    const q = query(historRef, orderBy('timestamp', 'desc'));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((doc) => doc.data());
    setHistory(data);
    setLoading(false);
  };

  useEffect(() => {
    setPermissions(getPermissions('wallet'));
    getInfo();
  }, []);

  return permissions.read ? (
    <Card sx={cardStyle}>
      <CustomSnackbar options={snackbarOptions} setOptions={setSnackbarOptions} />
      <ShowAddWalletMovement
        options={movementOptions}
        setOptions={setMovementOptions}
        username={username}
        onSuccess={() => {
          setMovementOptions({
            ...movementOptions,
            open: false,
          });
          setSnackbarOptions({
            open: true,
            message: 'Movimiento cargado con exíto',
            type: 'success',
          });
          getInfo();
        }}
        onError={() => {
          setSnackbarOptions({
            open: true,
            message: 'Hubo un error realizando este movimiento',
            type: 'error',
          });
        }}
      />

      <ShowHistoryModal open={openHistory} setOpen={setOpenHistory} history={history} />
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Billetera </Typography>
        {!loading && (
          <IconButton
            onClick={() => {
              setOpenHistory(true);
            }}
          >
            <Iconify icon="ph:eye" />
          </IconButton>
        )}
      </Grid>
      {loading ? (
        <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid xs={12} sm={6}>
            <Box
              sx={{
                height: 100,
                borderRadius: 3,
                bgcolor: alpha(theme.palette.grey[500], 0.12),
                padding: 2,
                direction: 'row',
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Iconify icon="tabler:coin-filled" color="#F1C40F" width={60} />
              <div>
                <Typography variant="subtitle1">Saldo</Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  $ {Intl.NumberFormat('en-DE').format(balance || '0')}
                </Typography>
              </div>
            </Box>
            {(permissions.edit || permissions.write) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 4,
                  marginTop: 10,
                }}
              >
                <Button
                  onClick={() => {
                    setMovementOptions({
                      username,
                      type: 'withdrawal',
                      open: true,
                    });
                  }}
                  variant="contained"
                  fullWidth
                  color="error"
                >
                  Retirar
                </Button>
                <Button
                  onClick={() => {
                    setMovementOptions({
                      username,
                      type: 'income',
                      open: true,
                    });
                  }}
                  variant="contained"
                  fullWidth
                  color="success"
                >
                  Recargar
                </Button>
              </div>
            )}
          </Grid>

          <Grid
            xs={12}
            sm={6}
            style={{
              height: '155px',
              overflow: 'scroll',
            }}
          >
            {history.length > 0 ? (
              history?.map((item) => <MovementItem item={item} key={item.id} />)
            ) : (
              <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                <Typography variant="subtitle1">No hay movimientos</Typography>
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </Card>
  ) : null;
}

function MovementItem({ item }) {
  const theme = useTheme();

  return (
    <Grid container display="flex" direction="row" alignItems="center" justifyContent="space-between">
      <Grid xs={12} md={9} display="flex" direction="row" alignItems="center">
        {item.type === 'income' ? (
          <Iconify icon="emojione-monotone:up-arrow" color="#60D03E" width={30} />
        ) : (
          <Iconify icon="emojione-monotone:down-arrow" color="#FF4842" width={30} />
        )}
        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column', width: ' 100% ' }}>
          <Typography variant="subtitle1">{item.description}</Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            {fDateTime(item.timestamp)}
          </Typography>
        </div>
      </Grid>
      <Grid xs={12} md={3}>
        <Typography
          variant="subtitle1"
          sx={{
            color: item.type === 'income' ? '#60D03E' : '#FF4842',
            textAlign: 'end',
            [theme.breakpoints.down('md')]: {
              textAlign: 'start',
              marginLeft: '36px',
            },
          }}
        >
          {item.type === 'income' ? ' + ' : ' - '}$ {Intl.NumberFormat('en-DE').format(item.value || '0')}
        </Typography>
      </Grid>
    </Grid>
  );
}

WalletCard.propTypes = {
  username: PropTypes.string,
  cardStyle: PropTypes.object,
};

MovementItem.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    timestamp: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.number,
  }),
};
