import { Card, Avatar, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Iconify from '../../../components/iconify/Iconify';

import { fDate } from '../../../utils/formatTime';

const Subtitle = styled('div')(({ theme }) => ({
  color: 'text.primary',
  textAlign: 'center',
  fontWeight: 600,
  lineHeight: 1.5,
  fontSize: '1rem',
  [theme.breakpoints.down('md')]: {
    lineHeight: 1,
  },
}));

DriverInfoCard.propTypes = {
  driver: PropTypes.shape({
    profile_picture: PropTypes.string,
    sub: PropTypes.string,
    name: PropTypes.string,
    phone_number: PropTypes.string,
    email: PropTypes.string,
    dni: PropTypes.string,
    rating: PropTypes.number,
    UserCreateDate: PropTypes.string,
    rideCount: PropTypes.number,
  }).isRequired,
  cardStyle: PropTypes.object,
};

export default function DriverInfoCard({ driver, cardStyle }) {
  const theme = useTheme();

  const [avatar, setAvatar] = useState('avatar_default');

  const getRandomAvatar = () => {
    setAvatar(`avatar_${Math.floor(Math.random() * 24) + 1}`);
  };

  useEffect(() => {
    getRandomAvatar();
  }, []);

  return (
    <Card sx={cardStyle}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item="true" xs={12} sm={4}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Avatar
              sx={{ width: 100, height: 100 }}
              src={driver.profile_picture ? driver.profile_picture : `/assets/images/avatars/${avatar}.jpg`}
              alt="Profile picture"
            />
            <Typography variant="h4" sx={{ marginTop: 1, marginBottom: 1, textAlign: 'center', lineHeight: 1 }}>
              {driver.name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'center', fontSize: '12px' }}>
              {driver.sub}
            </Typography>
          </Grid>
        </Grid>
        <Grid item="true" xs={12} sm={8} sx={{ marginTop: 1 }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="space-evenly">
            <Grid item="true" xs={4}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Iconify icon="noto:star" />
                <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                  Raiting
                </Typography>

                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  {driver.rating !== null && driver.rating !== undefined ? Math.round(driver.rating * 100) / 100 : 0}
                </Typography>
              </Grid>
            </Grid>

            <Grid item="true" xs={4}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Iconify icon="twemoji:sport-utility-vehicle" />
                <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                  Viajes
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  {driver.rideCount}
                </Typography>
              </Grid>
            </Grid>
            <Grid item="true" xs={4}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Iconify icon="flat-color-icons:calendar" />
                <Subtitle theme={theme}>Miembro desde</Subtitle>
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  {fDate(driver.UserCreateDate, 'dd/MM/yy')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
