import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { getCurrentUser } from 'actions/auth';

// components
import { useState, useEffect } from 'react';
import {
  getDocs,
  doc,
  collection,
  query,
  updateDoc,
  getDoc,
  where,
  getAggregateFromServer,
  count,
} from '@firebase/firestore';
import { firestore } from 'config/firebase';
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const [account, setAccount] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeCarDrivers, setActiveCarDrivers] = useState(0);
  const [activeMotoDrivers, setActiveMotoDrivers] = useState(0);
  const [activeTrips, setActiveTrips] = useState(0);
  const [finishedTrips, setFinishedTrips] = useState(0);

  const getActiveDrivers = async () => {
    const carsRef = collection(firestore, 'Locations', 'CAR', 'locations');
    const motoRef = collection(firestore, 'Locations', 'MOTO', 'locations');

    // const q = query(ref, where("status", "==", 'available'));
    const carsQuery = query(carsRef);
    const motoQuery = query(motoRef);

    const carsSnapshot = await getAggregateFromServer(carsQuery, {
      countOfDocs: count(),
    });
    const motoSnapshot = await getAggregateFromServer(motoQuery, {
      countOfDocs: count(),
    });
    setActiveCarDrivers(carsSnapshot.data().countOfDocs);
    setActiveMotoDrivers(motoSnapshot.data().countOfDocs);
  };

  const getActiveTrips = async () => {
    const ref = collection(firestore, 'TravelInfo');
    const q = query(ref, where('status', 'in', ['started', 'accepted']));

    const snapshot = await getAggregateFromServer(q, {
      countOfDocs: count(),
    });
    setActiveTrips(snapshot.data().countOfDocs);
  };

  const getFinishedTrips = async () => {
    const ref = collection(firestore, 'TravelHistory');
    const q = query(ref, where('status', '==', 'completed'));

    const snapshot = await getAggregateFromServer(q, {
      countOfDocs: count(),
    });
    setFinishedTrips(snapshot.data().countOfDocs);
  };

  const getInfo = async () => {
    setLoading(true);
    getCurrentUser().then((data) => {
      setAccount(data);
    });
    await getActiveDrivers();
    await getActiveTrips();
    await getFinishedTrips();
    setLoading(false);
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Luguu</title>
      </Helmet>

      {loading ? (
        <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }} color="#93278f">
            Bienvenido, {account.name}
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="Vehículos activos" total={activeCarDrivers} icon={'fa-solid:car'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Motos activas"
                total={activeMotoDrivers}
                color="info"
                icon={'ri:motorbike-fill'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="Viajes activos" total={activeTrips} color="success" icon={'bx:trip'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Viajes finalizados"
                total={finishedTrips}
                color="warning"
                icon={'ic:baseline-mode-of-travel'}
              />
            </Grid>

            {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Usuarias Nuevas"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Descargas Trimestrales"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conductoras por ciudad"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Usuarios Recurrentes"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
          </Grid>
        </Container>
      )}
    </>
  );
}
