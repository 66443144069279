import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';

const permissions = {
  admin: {
    pages: '*',
  },
  user: {
    pages: { dashboard: ['read', 'write', 'edit', 'delete'], users: ['read', 'write'],  drivers: ['read', 'write'], documents: ['read', 'write'], fares: ['read', 'edit'],  wallet: ['read', 'edit']  },
  },
};

const getPermissions = (page) => {
  const decrypted = CryptoJS.AES.decrypt(Cookies.get('user_data'), process.env.REACT_APP_CRYPTO_KEY).toString(
    CryptoJS.enc.Utf8
  );
  const user = JSON.parse(decrypted);
  let permission = {
    read: false,
    write: false,
    edit: false,
    delete: false,
  };
  user.groups?.forEach((group) => {
    if (permissions[group]?.pages === '*') {
      permission = {
        read: true,
        write: true,
        edit: true,
        delete: true,
      };
    } else if (permissions[group]?.pages?.[page]) {
      permissions[group]?.pages?.[page].forEach((item) => {
        permission[item] = true;
      });
    }
  });
  return permission
};


const usePagePermissionCheck = (page) => {
  const navigate = useNavigate();
  const permission = getPermissions(page)
  if(!permission.read){
    navigate('/401', { replace: true });
  }
}

export { getPermissions, usePagePermissionCheck };
