import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

export default function AlertDialog({ options, setOptions }) {
  const handleClose = () => {
    setOptions({
      open: false,
      title: null,
      message: null,
      showCancelButton: false,
      cancelButtonFocused: false,
      onAcceptClick: null,
      onCancelClick: null,
    });
  };

  return (
    <Dialog
      open={options.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{options.title}</DialogTitle>
      {options.message && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{options.message}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        {options.showCancelButton && (
          <Button
            onClick={() => {
              if (options.onCancelClick) options.onCancelClick();
              else handleClose();
            }}
            variant={options.cancelButtonFocused ? 'contained' : 'outlined'}
          >
            Cancelar
          </Button>
        )}
        <Button
          onClick={() => {
            if (options.onAcceptClick) options.onAcceptClick();
            else handleClose();
          }}
          variant={options.cancelButtonFocused ? 'outlined' : 'contained'}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  options: PropTypes.shape({
    open: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    showCancelButton: PropTypes.bool,
    cancelButtonFocused: PropTypes.bool,
    onAcceptClick: PropTypes.func,
    onCancelClick: PropTypes.func,
  }).isRequired,
  setOptions: PropTypes.func.isRequired,
};
