/* eslint-disable camelcase */
import * as React from 'react';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Container,
  Grid,
  Modal,
  Fade,
  Backdrop,
  Typography,
  TextField,
  Drawer,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { editUser } from '../../actions/usersActions';

import { useMediaContext } from '../../state/media';
import { validateTokenError } from '../../actions/auth';

const CloseIconStyle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  color: 'grey',
  ':hover': { color: 'black' },
}));

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
};

export default function EditUserModal({ open, setOpen, user, onSuccess, onError }) {
  const { isSmallScreen } = useMediaContext();
  const [loading, setLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = JSON.stringify(Object.fromEntries(new FormData(event.currentTarget)));
    const userData = JSON.parse(data);
    userData.id = user.id;
    if (validateFields(userData)) {
      setLoading(true);
      editUser(userData)
        .then(() => {
          if (onSuccess) onSuccess();
          setLoading(false);
          setOpen(false);
        })
        .catch((error) => {
          console.error(error);
          validateTokenError(error);
          if (onError) onError();
          setLoading(false);
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const validateFields = (userData) => {
    const { name, email, phone_number, dni } = userData;

    const emailRegex = /\S+@\S+\.\S+/;
    const phoneRegex = /^\+[0-9]{12}$/;
    const dniRegex = /^((\d{8})|(\d{10})|(\d{11})|(\d{6}-\d{5}))?$/;

    if (user.name === name && user.email === email && user.phone_number === phone_number && user.dni === dni) {
      return false;
    }
    if (name === '' || email === '' || phone_number === '') {
      setErrorMessage('Todos los campos son obligatorios');
      setShowErrorMessage(true);
      return false;
    }
    if (!emailRegex.test(email)) {
      setErrorMessage('Ingrese un correo válido');
      setShowErrorMessage(true);
      return false;
    }
    if (!phoneRegex.test(phone_number)) {
      setErrorMessage('Ingrese un celular válido');
      setShowErrorMessage(true);
      return false;
    }
    if (!dniRegex.test(dni)) {
      setErrorMessage('Ingrese un cédula válida');
      setShowErrorMessage(true);
      return false;
    }

    return true;
  };

  return isSmallScreen ? (
    <Drawer anchor="bottom" open={open} onClose={toggleDrawer(false)}>
      <Box p={2}>
        <CloseIconStyle>
          <CloseIcon onClick={toggleDrawer(false)} className="test" />
        </CloseIconStyle>
        <FormEditUser user={user} handleSubmit={handleSubmit} loading={loading} />
        {showErrorMessage && (
          <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
            <Alert severity="error" sx={{ bgcolor: 'background.paper', color: 'red' }}>
              {errorMessage}
            </Alert>
          </div>
        )}
      </Box>
    </Drawer>
  ) : (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={boxStyle}>
          <CloseIconStyle>
            <CloseIcon onClick={toggleDrawer(false)} className="test" />
          </CloseIconStyle>
          <FormEditUser user={user} handleSubmit={handleSubmit} loading={loading} />
          {showErrorMessage && (
            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <Alert severity="error" sx={{ bgcolor: 'background.paper', color: 'red' }}>
                {errorMessage}
              </Alert>
            </div>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}

const FormEditUser = ({ user, handleSubmit, loading }) => (
  <Container component="main">
    <Typography component="h1" variant="h5">
      Editar usuario
    </Typography>

    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField disabled required fullWidth id="id" label="Username" name="id" defaultValue={user?.id || ''} />
        </Grid>
        <Grid item xs={12}>
          <TextField required fullWidth id="name" label="Nombre" name="name" defaultValue={user?.name || ''} />
        </Grid>
        <Grid item xs={12}>
          <TextField required fullWidth id="email" label="Correo" name="email" defaultValue={user?.email || ''} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="phone_number"
            label="Celular"
            name="phone_number"
            defaultValue={user?.phone_number || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth id="dni" label="Cédula" name="dni" defaultValue={user?.dni || ''} />
        </Grid>
      </Grid>
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        {loading ? <CircularProgress color="inherit" sx={{ p: 1 }} /> : 'Editar'}
      </Button>
    </Box>
  </Container>
);

EditUserModal.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    dni: PropTypes.string,
    created_at: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

FormEditUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    dni: PropTypes.string,
    created_at: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
