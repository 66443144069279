import Cookies from 'js-cookie';
import clientAxios from '../config/axios';

const getDocuments = async (username) => {
  const token = Cookies.get('token');
  const response = await clientAxios.get(`/documents?username=${username}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  const body = response.data;
  if (body.ok === true) {
    return body.data;
  }
  return response;
};

const updateDocument = async (username, document) => {
  const token = Cookies.get('token');
  const response = await clientAxios.put(
    '/documents/status',
    {
      username,
      documents: [document],
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
  return response;
};

const checkIfDocumentsAreComplete = async (username) => {
  const token = Cookies.get('token');
  const documents = {
    'profile-picture': false,
    'dni-photo': false,
    'dni-front': false,
    'dni-back': false,
    'driver-license-front': false,
    'driver-license-back': false,
    'vehicle-ownership': false,
    soat: false,
  };

  try {
    const response = await clientAxios.get(`/documents?username=${username}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    const body = response.data;
    if (body.ok === true) {
      body.data.forEach((document) => {
        const id = document.Key.split('/').reverse()[0].split('.')[0];
        documents[id] = true;
      });
    }
  } catch (error) {
    console.error(error);
  }

  return Object.values(documents).every(Boolean);
};

export { getDocuments, updateDocument, checkIfDocumentsAreComplete };
