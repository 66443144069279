/* eslint-disable camelcase */
import * as React from 'react';
import { useState } from 'react';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

import PropTypes from 'prop-types';
import { getDoc, doc, setDoc } from '@firebase/firestore';

import {
  Box,
  Button,
  Container,
  Modal,
  Fade,
  Backdrop,
  Typography,
  TextField,
  Drawer,
  Alert,
  CircularProgress,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useMediaContext } from 'state/media';
import CurrencyField from 'components/ui/CurrencyField';
import { firestore } from 'config/firebase';

const CloseIconStyle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  color: 'grey',
  ':hover': { color: 'black' },
}));

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
};
const helperButton = {
  color: 'grey',
  fontWeight: 500,
  border: '1px solid #C4CDD5',
  padding: '0 5px',
  textTransform: 'none',
};

const AddWalletMovement = ({ username, type, onSuccess, onError }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [value, setValue] = useState();
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);

  const decrypted = CryptoJS.AES.decrypt(Cookies.get('user_data'), process.env.REACT_APP_CRYPTO_KEY).toString(
    CryptoJS.enc.Utf8
  );
  const user = JSON.parse(decrypted);

  const addMomevement = async () => {
    if (!username) return;

    if (!value || Number.isNaN(value)) {
      setErrorMessage('Ingrese un valor válido');
      setShowErrorMessage(true);
      return;
    }

    if (!description) {
      setErrorMessage('Ingrese una descripción válida');
      setShowErrorMessage(true);
      return;
    }

    setLoading(true);

    const balanceRef = doc(firestore, 'Wallet', username);
    const balanceSnap = await getDoc(balanceRef);
    let balance = 0;
    if (balanceSnap.exists()) {
      // eslint-disable-next-line prefer-destructuring
      balance = balanceSnap.data().balance;
    }

    if (Number.isNaN(balance)) {
      balance = 0;
    }

    balance = type === 'income' ? balance + value : balance - value;

    const timestamp = Date.now();
    const id = `plt_${user.sub}_${timestamp}`;

    try {
      await setDoc(doc(firestore, 'Wallet', username, 'History', id), {
        description,
        id,
        timestamp,
        type,
        value,
        made_for: user.name,
        user_id: user.sub,
      });
      await setDoc(balanceRef, { balance });
      setLoading(false);
      if (onSuccess) onSuccess();
    } catch (error) {
      setLoading(false);
      console.error(`Unsuccessful addMovement ${error}`);
      if (onError) onError();
    }
  };

  return (
    <Container component="main">
      <Typography component="h1" variant="h5">
        Agregar Movimiento
      </Typography>
      <TextField
        fullWidth
        id="descrption"
        name="descrption"
        placeholder="Descripción"
        value={description}
        onChange={(event) => {
          setDescription(event.target.value);
        }}
        variant="standard"
        style={{ marginTop: '10px' }}
      />

      {type === 'income' ? (
        <div style={{ marginTop: 10, rowGap: 10 }}>
          <Button size="small" color="info" sx={helperButton} onClick={() => setDescription('Recarga de billetera')}>
            Recarga de billetera
          </Button>

          <Button size="small" color="info" sx={helperButton} onClick={() => setDescription('Bono')}>
            Bono
          </Button>
        </div>
      ) : (
        <div style={{ marginTop: 10, rowGap: 10 }}>
          <Button size="small" color="info" sx={helperButton} onClick={() => setDescription('Ajuste')}>
            Ajuste
          </Button>

          <Button size="small" color="info" sx={helperButton} onClick={() => setDescription('Descuento por')}>
            Descuento por
          </Button>
        </div>
      )}

      <CurrencyField
        value={value}
        onChange={(event) => {
          setValue(parseInt(event.target.value, 10));
        }}
        name="value"
        style={{ width: '100%', marginTop: '10px' }}
        label="Valor"
      />

      {showErrorMessage && (
        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
          <Alert severity="error" sx={{ bgcolor: 'background.paper', color: 'red' }}>
            {errorMessage}
          </Alert>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        {type === 'income' ? (
          <Button onClick={addMomevement} variant="contained" color="success" disabled={loading}>
            {loading ? <CircularProgress color="inherit" sx={{ p: 1 }} /> : 'Recargar'}
          </Button>
        ) : (
          <Button onClick={addMomevement} variant="contained" color="error" disabled={loading}>
            {loading ? <CircularProgress color="inherit" sx={{ p: 1 }} /> : 'Retirar'}
          </Button>
        )}
      </div>
    </Container>
  );
};

export default function ShowAddWalletMovement({ options, setOptions, onSuccess, onError }) {
  const { isSmallScreen } = useMediaContext();

  const handleClose = () => {
    setOptions({
      open: false,
      username: '',
      type: '',
    });
  };

  const toggleDrawer = (newOpen) => () => {
    setOptions({
      ...options,
      open: newOpen,
    });
  };

  return isSmallScreen ? (
    <Drawer anchor="bottom" open={options.open} onClose={toggleDrawer(false)}>
      <Box p={2}>
        <CloseIconStyle>
          <CloseIcon onClick={toggleDrawer(false)} className="test" />
        </CloseIconStyle>
        <AddWalletMovement username={options.username} type={options.type} onSuccess={onSuccess} onError={onError} />
      </Box>
    </Drawer>
  ) : (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={options.open}
      onClose={handleClose}
      sx={{ overflow: 'scroll' }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={options.open}>
        <Box sx={boxStyle}>
          <CloseIconStyle>
            <CloseIcon onClick={toggleDrawer(false)} className="test" />
          </CloseIconStyle>
          <AddWalletMovement username={options.username} type={options.type} onSuccess={onSuccess} onError={onError} />
        </Box>
      </Fade>
    </Modal>
  );
}

AddWalletMovement.propTypes = {
  username: PropTypes.string,
  type: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

ShowAddWalletMovement.propTypes = {
  options: PropTypes.shape({
    open: PropTypes.bool,
    username: PropTypes.string,
    type: PropTypes.string,
  }),
  setOptions: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};
