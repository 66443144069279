/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import PropTypes from 'prop-types';

// @mui
import {
  Button,
  Card,
  Table,
  Stack,
  Paper,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CustomSnackbar } from '../../components/ui/Snackbars';

import Label from '../../components/label/Label';
import Iconify from '../../components/iconify/Iconify';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import { getDrivers, getDriversCount } from '../../actions/driversActions';
import AlertDialog from '../../components/ui/Dialogs';
import DriverDetails from './DriverDetails';
import { validateTokenError } from '../../actions/auth';
import { usePagePermissionCheck } from '../../permissions';
import { fDate } from '../../utils/formatTime';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nombre', alignRight: false },
  { id: 'email', label: 'Correo', alignRight: false },
  { id: 'phone_number', label: 'Celular', alignRight: false },
  { id: 'dni', label: 'Cédula', alignRight: false },
  { id: 'id', label: 'Username', alignRight: false },
  { id: 'created_at', label: 'Fecha Creación', alignRight: false },
  { id: 'status', label: 'Estado', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.phone_number.indexOf(query) !== -1 ||
        _user.id.indexOf(query) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const StyledButtons = styled('div')(({ theme }) => ({
  direction: 'row',
  display: 'flex',
  width: '100%',
  justifyContent: 'end',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    display: 'flex',
  },
}));

export default function Drivers() {
  usePagePermissionCheck('drivers');
  const { search } = useLocation();
  const username = new URLSearchParams(search).get('username');

  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchCount, setSearchCount] = useState(0);
  const [page, setPage] = useState(0);
  const [order] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filterGroup, setFilterGroup] = useState('driver');
  const [drivers, setDrivers] = useState({});
  const [driversCount, setDriversCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [paginationTokens, setPaginationTokens] = useState({});

  const [snackbarOptions, setSnackbarOptions] = useState({
    open: false,
    message: '',
    type: '',
  });

  const [dialogOptions, setDialogOptions] = useState({
    open: false,
    title: null,
    message: null,
    showCancelButton: false,
    cancelButtonFocused: false,
    onAcceptClick: null,
    onCancelClick: null,
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = drivers[page].map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setDrivers({});
    setPaginationTokens({});
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const getStatus = (status) => {
    if (status === 'true') return 'Verificado';
    return 'No verficado';
  };

  CustomTableRow.propTypes = {
    row: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      phone_number: PropTypes.string,
      dni: PropTypes.string,
      created_at: PropTypes.string,
      status: PropTypes.string,
    }).isRequired,
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - drivers.length) : 0;

  const isNotFound = !filteredData.length && !!filterName;

  const getDriversList = (customPage, customLimit) => {
    const currentPage = customPage || page;
    const limit = customLimit || rowsPerPage;
    getDrivers(filterGroup, paginationTokens[currentPage], limit)
      .then((response) => {
        const { data, paginationToken } = { data: response.data.users, paginationToken: response.data.paginationToken };
        if (paginationToken) {
          setPaginationTokens((prevState) => ({
            ...prevState,
            [currentPage + 1]: paginationToken,
          }));
        }
        const result = drivers[currentPage] || [];
        data.forEach((item) => {
          const element = {
            id: item.sub,
            name: item.name,
            email: item.email,
            phone_number: item.phone_number,
            dni: item['custom:dni'],
            created_at: item.UserCreateDate,
            status: item.phone_number_verified,
          };
          const index = result.findIndex((user) => user.id === element.id);
          if (index === -1) {
            result.push(element);
          } else {
            result[index] = element;
          }
        });
        setDrivers((prevState) => ({
          ...prevState,
          [currentPage]: result,
        }));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        validateTokenError(error);
        setSnackbarOptions({
          open: true,
          message: 'Error consultando las usuarias',
          type: 'error',
        });
        setFilterGroup('');
        console.error(error);
        setLoading(false);
      });
  };

  const setGroupFilter = (group) => {
    setPage(0);
    setDrivers({});
    setPaginationTokens({});
    if (filterGroup === group) {
      setFilterGroup();
    } else {
      setFilterGroup(group);
    }
  };

  const setTotalUsersCount = async () => {
    const count = await getDriversCount(filterGroup);
    setDriversCount(count);
  };

  function searchData() {
    if (filterName) {
      setSearching(true);
      let result = [];
      if (searchCount < driversCount / rowsPerPage) {
        if (drivers[searchCount]) {
          result = applySortFilter(drivers[searchCount], getComparator(order, orderBy), filterName);
          if (result.length === 0) {
            setSearchCount(searchCount + 1);
          } else {
            setSearching(false);
          }
        } else {
          getDriversList(searchCount);
        }
        setFilteredData(result);
      } else {
        setSearching(false);
      }
    } else {
      setSearchCount(0);
      setFilteredData([]);
    }
  }

  useEffect(() => {
    setLoading(true);
    getDriversList();
    setTotalUsersCount();
  }, [filterGroup]);

  useEffect(() => {
    searchData();
  }, [filterName, drivers, searchCount]);

  useEffect(() => {
    if (!drivers[page]) {
      setLoading(true);
      getDriversList();
    }
  }, [page, rowsPerPage]);

  return (
    <>
      <Helmet>
        <title> Conductoras | Luguu </title>
      </Helmet>
      <Container>
        <Grid container direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Grid xs={12} sm={4} md={7}>
            <Typography variant="h4" gutterBottom>
              Conductoras
            </Typography>
          </Grid>
          {!username && (
            <Grid xs={12} sm={8} md={5} display="flex" justifyContent="end">
              <StyledButtons>
                <Grid container direction="row" justifyContent="center" alignItems="center" gap={1}>
                  <Button
                    variant={filterGroup === 'driver' ? 'contained' : 'outlined'}
                    color="primary"
                    onClick={() => setGroupFilter('driver')}
                  >
                    Aprobadas
                  </Button>
                  <Button
                    variant={filterGroup === 'driver_request' ? 'contained' : 'outlined'}
                    color="warning"
                    onClick={() => setGroupFilter('driver_request')}
                  >
                    Pendientes
                  </Button>
                  {/* <Button
                    variant={filterGroup === 'newborn_driver' ? 'contained' : 'outlined'}
                    color="info"
                    onClick={() => setGroupFilter('newborn_driver')}
                  >
                    Sin solicitud
                  </Button> */}
                </Grid>
              </StyledButtons>
            </Grid>
          )}
        </Grid>

        {username ? (
          <DriverDetails username={username} />
        ) : (
          <Card>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              refreshFunction={() => {
                getDriversList(page, rowsPerPage);
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={driversCount}
                    numSelected={selected.length}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  {loading ? (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <CircularProgress color="primary" />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {filteredData.length > 0
                        ? filteredData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => <CustomTableRow row={row} key={row.id} />)
                        : !isNotFound && drivers[page]?.map((row) => <CustomTableRow row={row} key={row.id} />)}

                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  )}

                  {searching ? (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <CircularProgress color="primary" />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                No encontrado
                              </Typography>

                              <Typography variant="body2">
                                No se encontró ningún resultado para &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Intenta buscando por celular o username
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={filteredData.length > 0 ? filteredData.length : driversCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>
      <AlertDialog options={dialogOptions} setOptions={setDialogOptions} />
      <CustomSnackbar options={snackbarOptions} setOptions={setSnackbarOptions} />
    </>
  );

  function CustomTableRow({ row }) {
    const { id, name, email, phone_number, dni, created_at, status } = row;
    const selectedUser = selected.indexOf(id) !== -1;

    return (
      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
        <TableCell padding="checkbox">
          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
        </TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">{email}</TableCell>
        <TableCell align="left">{phone_number}</TableCell>
        <TableCell align="left">{dni}</TableCell>
        <TableCell align="left">{id}</TableCell>
        <TableCell align="left">{fDate(created_at, 'dd/MM/yy')}</TableCell>

        <TableCell align="left">
          <Label color={status !== 'true' ? 'error' : 'success'}>{getStatus(status)}</Label>
        </TableCell>
        <TableCell align="right">
          <Link to={`/dashboard/conductoras?username=${id}`}>
            <IconButton size="large" color="primary">
              <Iconify icon="ph:eye" />
            </IconButton>
          </Link>
        </TableCell>
      </TableRow>
    );
  }
}
