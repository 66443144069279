import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const defaultState = {
  isDesktopOrLaptop: false,
  isDesktopOrLaptopDevice: false,
  isBigScreen: false,
  isTabletOrMobile: false,
  isTabletOrMobileDevice: false,
  isBiggerThanMobile: false,
  isSmallScreen: false,
  isSmallScreenDevice: false,
  isMediumScreen: false,
  isXsScreenDevice: false,
  isXsScreen: false,
  isPortrait: false,
  isRetina: false,
  isClient: false,
  windowSize: {
    width: undefined,
    height: undefined,
  },
  isMobile: false,
  isDesktop: false,
  isMyRentsOpen: false,
  setIsMyRentsOpen: () => {},
  isSettingsOpen: false,
  setIsSettingsOpen: () => {},
  isMyPropertiesOpen: false,
  setIsMyPropertiesOpen: () => {},
};

export const MediaContext = React.createContext(defaultState);

export const useMediaContext = () => React.useContext(MediaContext);

export function MediaProvider({ children }) {
  const [isMyRentsOpen, setIsMyRentsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isMyPropertiesOpen, setIsMyPropertiesOpen] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  const isDesktopOrLaptopDevice = useMediaQuery({
    query: '(min-device-width: 1224px)',
  });

  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' });

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)',
  });

  const isBiggerThanMobile = useMediaQuery({ query: '(min-width: 768px)' });

  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });

  const isMediumScreen = useMediaQuery({ query: '(max-width: 950px)' });

  const isSmallScreenDevice = useMediaQuery({
    query: '(max-device-width: 768px)',
  });

  const isXsScreenDevice = useMediaQuery({
    query: '(max-device-width: 410px)',
  });

  const isXsScreen = useMediaQuery({ query: '(max-width: 410px)' });

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    function updateWindowDimension() {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener('resize', updateWindowDimension);
    updateWindowDimension();
    return () => window.removeEventListener('resize', updateWindowDimension);
  }, []);

  return (
    <MediaContext.Provider
      value={{
        isDesktopOrLaptop,
        isDesktopOrLaptopDevice,
        isBigScreen,
        isTabletOrMobile,
        isTabletOrMobileDevice,
        isBiggerThanMobile,
        isSmallScreen,
        windowSize,
        isMobile: windowSize.width < 768,
        isDesktop: windowSize.width >= 768,
        isSmallScreenDevice,
        isMediumScreen,
        isXsScreenDevice,
        isXsScreen,
        isPortrait,
        isRetina,
        isClient,
        isMyRentsOpen,
        setIsMyRentsOpen,
        isSettingsOpen,
        setIsSettingsOpen,
        isMyPropertiesOpen,
        setIsMyPropertiesOpen,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
}
