import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const conductores = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  cedula: faker.random.numeric(5),
  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  nombre: faker.name.fullName(),
  email: faker.internet.exampleEmail(),
  celular: faker.phone.number(),
  fechacreacion: faker.date.weekday(),
  estatus: sample(['Activo', 'Inactivo']),
}));

export default conductores;
