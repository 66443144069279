import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import Carros from './pages/Carros/carros'
import Users from './pages/Users/users';
import Drivers from './pages/Drivers/drivers';
import DriverDetails from './pages/Drivers/DriverDetails';
import Documentos from './pages/Documentos/documentos';
import DetalleDocumentos from './pages/Documentos/detalledocumentos';
import PromotionalCode from './pages/PromoCode/promotionalCode';
import RaitingConductor from './pages/Puntuacion/raitingConductor';
import RaitingUsuario from './pages/Puntuacion/raitingUsuario';
import Viajes from './pages/Viajes/viajes';
import Pagos from './pages/Pagos/pagos';
import Notificaciones from './pages/Notificaciones/notificaciones';
import Cancelaciones from './pages/Viajes/cancelaciones';
import Nuevovehiculo from './pages/Carros/nuevovehiculo';
import Nuevocodigo from './pages/PromoCode/nuevocodigo';
import Nuevanotificacion from './pages/Notificaciones/nuevanotificacion';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import NoAccessPage from './pages/NoAccessPage';
import Fares from './pages/Fares/fares';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/login" /> },
        { path: 'app', element: <DashboardAppPage />, index: true },
        { path: 'user', element: <UserPage /> },
        { path: 'carros', element: <Carros /> },
        { path: 'usuarias', element: <Users /> },
        { path: 'conductoras', element: <Drivers /> },
        { path: 'tarifas', element: <Fares /> },
        { path: 'documentos', element: <Documentos /> },
        { path: 'detalledocumentos', element: <DetalleDocumentos /> },
        { path: 'promotionalcode', element: <PromotionalCode /> },
        { path: 'raitingconductor', element: <RaitingConductor /> },
        { path: 'raitingusuario', element: <RaitingUsuario /> },
        { path: 'notificaciones', element: <Notificaciones /> },
        { path: 'cancelaciones', element: <Cancelaciones /> },
        { path: 'viajes', element: <Viajes /> },
        { path: 'pagos', element: <Pagos /> },
        { path: 'tipovehiculo', element: <Nuevovehiculo /> },
        { path: 'codigopromocional', element: <Nuevocodigo /> },
        { path: 'agregarnotificacion', element: <Nuevanotificacion /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: '/',
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '401', element: <NoAccessPage /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
