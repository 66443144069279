import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const Nuevocodigo = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    navigate('/dashboard', { replace: true });
  };

  const manejadorLogin = async () => {
    navigate('/dashboard/promotionalcode', { replace: true });
  };

  return (
    <>
      <Stack spacing={3}>
        <Typography variant="h4" gutterBottom>
          Agregar Nuevo Código Promocional
        </Typography>
        <TextField name="email" label="Código" />
        <TextField name="email" label="Valor" />
        <TextField name="email" label="Tipo" />
        <TextField name="email" label="Limite Uso" />
        <TextField name="email" label="Fecha" />
      </Stack>
      <br />
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={manejadorLogin}>
        Agregar
      </LoadingButton>
    </>
  );
};

export default Nuevocodigo;
