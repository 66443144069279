import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import clientAxios from '../config/axios';

const login = async (user) => {
  const response = await clientAxios.post(
    '/login/refresh',
    {
      username: `+57${user.username}`,
      password: user.password,
    },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
};

const refreshToken = () => {
  const username = Cookies.get('username');
  if (username) {
    clientAxios
      .post(`/login?username=${username}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const body = response.data;
        if (body.ok === true) {
          Cookies.set('token', body.token);
        }
      })
      .catch((error) => {
        console.error(error)
        Cookies.remove('token');
      });
  }
};

const validateTokenError = ({response}) => {
  if(response.data.error === "Invalid token"){
    refreshToken();
  }
}

const getCurrentUser = async () => {
  const username = Cookies.get('username');
  const token = Cookies.get('token');
  let data = {};
  try {
    const response = await clientAxios.get(`/users?username=${username}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    const body = response.data;
    if (body.ok === true) {
      // eslint-disable-next-line prefer-destructuring
      data = body.data;
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_CRYPTO_KEY).toString();
      Cookies.set('user_data', encrypted);
    }
  } catch (error) {
    console.error(error);
  }
  return data;
};

export { login, refreshToken, getCurrentUser, validateTokenError };
