import * as React from 'react';
import { Snackbar, Alert, AlertTitle } from '@mui/material';
import PropTypes from 'prop-types';

function CustomSnackbar({ options, setOptions }) {
  const handleClose = () => {
    setOptions({
      open: false,
      message: '',
      type: '',
    });
  };

  return (
    <Snackbar
      open={options.open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity={options.type} variant="filled" sx={{ width: '100%', color: 'white' }}>
        <AlertTitle mb={0}> {options.message}</AlertTitle>
      </Alert>
    </Snackbar>
  );
}

CustomSnackbar.propTypes = {
  options: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['error', 'warning', 'info', 'success', '']).isRequired,
  }).isRequired,
  setOptions: PropTypes.func.isRequired,
};

export { CustomSnackbar };
