import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const PRODUCT_NAME = [
  'Documento Legal',
  'Cedula Frontal',
  'Cedula Trasera',
  'Licencia Frontal',
  'Licencia Trasera',
  'Seguro SOAT',
  'Usuario con cedula',
];
const PRODUCT_COLOR = ['#00AB55', '#000000', '#FFFFFF', '#FFC0CB', '#FF4842', '#1890FF', '#94D82D', '#FFC107'];

const products = [...Array(7)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: faker.datatype.uuid(),
    cover: `/assets/images/documents/documento_${setIndex}.png`,
    name: PRODUCT_NAME[index],
    colors:
      PRODUCT_COLOR,
  };
});

export default products;
