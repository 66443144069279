/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect, useCallback } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import PropTypes from 'prop-types';

import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { Card, Typography, Button, Box, Alert } from '@mui/material';
import { setDefaults, fromAddress } from 'react-geocode';
import Iconify from '../iconify';

const MAP_LIBRARIES = ['places']; // Define the libraries array

FareExplorer.propTypes = {
  fares: PropTypes.shape({
    CAR: PropTypes.shape({
      km: PropTypes.number,
      min: PropTypes.number,
      minValue: PropTypes.number,
    }),
    MOTO: PropTypes.shape({
      km: PropTypes.number,
      min: PropTypes.number,
      minValue: PropTypes.number,
    }),
  }),
  fee:  PropTypes.number
};

const apiKey =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_PROD_MAPS_apiKey
    : process.env.REACT_APP_DEV_MAPS_apiKey;

export default function FareExplorer({ fares, fee }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
    libraries: MAP_LIBRARIES,
  });
  setDefaults({
    key: apiKey,
    language: 'es',
    region: 'co',
  });
  const [center, setCenter] = useState({
    lat: 6.2476,
    lng: -75.5658,
  });
  const [zoom, setZoom] = useState(10);
  const [distanceMatrixService, setDistanceMatrixService] = useState();
  const [origin, setOrigin] = useState({});
  const [originMarker, setOriginMarker] = useState();
  const [destinationMarker, setDestinationMarker] = useState();
  const [routeParams, setRouteParams] = useState();

  const [destination, setDestination] = useState({});
  const [prices, setPrices] = useState({});
  const [showPrices, setShowPrices] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const errorMessage = 'Debe escoger dos ubicaciones válidas';

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
  }, []);

  const setOriginCoordinates = (address) => {
    fromAddress(address)
      .then(({ results }) => {
        const coordinates = results[0].geometry.location;
        setCenter(coordinates);
        setZoom(15);
        setOrigin({
          address,
          coordinates,
        });
        setOriginMarker(
          <Marker
            key={`origin-${Math.random().toString(36).substr(2, 9)}`}
            position={{ lat: coordinates.lat, lng: coordinates.lng }}
          />
        );
      })
      .catch(console.error);
  };

  const setDestinationCoordinates = (address) => {
    fromAddress(address)
      .then(({ results }) => {
        const coordinates = results[0].geometry.location;
        setCenter(coordinates);
        setZoom(15);
        setDestination({
          address,
          coordinates,
        });
        setDestinationMarker(
          <Marker
            key={`destination-${Math.random().toString(36).substr(2, 9)}`}
            position={{ lat: coordinates.lat, lng: coordinates.lng }}
          />
        );
      })
      .catch(console.error);
  };

  const getDistance = () => {
    distanceMatrixService.getDistanceMatrix(
      {
        destinations: [destination.address],
        origins: [origin.address],
        travelMode: 'DRIVING',
      },
      (res, status) => {
        if (status === 'OK') {
          setRouteParams({
            time: res.rows[0].elements[0].duration.text,
            distance: res.rows[0].elements[0].distance.text,
          });
        }
      }
    );
  };

  const getFares = (type) => {
    const { time, distance } = routeParams;
    const kmValue = parseFloat(distance?.split(' ')[0]) * fares[type]?.km;
    const minValue = parseFloat(time?.split(' ')[0]) * fares[type]?.min;
    const total = kmValue + minValue;
    const minimunValue = fares[type]?.minValue;
    setPrices((prevState) => ({
      ...prevState,
      [type]: total > minimunValue ? total : minimunValue,
    }));
  };

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    setDistanceMatrixService(new window.google.maps.DistanceMatrixService());
  }, [isLoaded]);

  useEffect(() => {
    if (routeParams) {
      getFares('CAR');
      getFares('MOTO');
    }
  }, [routeParams]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Card sx={{ padding: '30px' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Explorador de Tarifas
        </Typography>
      </Grid>

      <Grid container spacing={2}>
        <Grid xs={12} sm={6}>
          <div
            style={{
              width: '100%',
              height: '455px',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '15px' }}
              zoom={zoom}
              center={center}
              mapTypeId="roadmap"
              onLoad={onLoad}
            >
              {originMarker}
              {destinationMarker}
            </GoogleMap>
          </div>
        </Grid>
        <Grid xs={12} sm={6}>
          <Typography variant="subtitle1">Origen</Typography>
          <GooglePlacesAutocomplete
            placeholder="Search"
            label="location"
            autocompletionRequest={{
              componentRestrictions: {
                country: ['co'],
              },
            }}
            selectProps={{
              isClearable: true,
              onChange: async (o) => {
                setShowPrices(false);
                setPrices({});
                setRouteParams({});
                if (o?.label) {
                  setOriginCoordinates(o.label);
                } else {
                  setOrigin({});
                }
              },
            }}
          />

          <Typography variant="subtitle1" sx={{ marginTop: 1 }}>
            Destino
          </Typography>
          <GooglePlacesAutocomplete
            placeholder="Search"
            label="location"
            autocompletionRequest={{
              componentRestrictions: {
                country: ['co'],
              },
            }}
            selectProps={{
              isClearable: true,
              onChange: (o) => {
                setShowPrices(false);
                if (o?.label) {
                  setDestinationCoordinates(o.label);
                } else {
                  setDestination({});
                }
              },
            }}
          />
          {showErrorMessage && (
            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <Alert severity="error" sx={{ bgcolor: 'background.paper', color: 'red' }}>
                {errorMessage}
              </Alert>
            </div>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => {
              if (origin.address && destination.address) {
                setShowErrorMessage(false);
                setPrices({});
                setRouteParams({});
                getDistance();
                setShowPrices(true);
              } else {
                setShowErrorMessage(true);
              }
            }}
            sx={{ marginTop: 3 }}
          >
            Guardar
          </Button>
          {showPrices && (
            <>
              {' '}
              <Box
                sx={{
                  width: '100%',
                  borderRadius: 1,
                  marginTop: 5,
                  marginBottom: 5,

                  border: '1px solid #E5E8E8',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '20px',
                  gap: '40px',
                }}
              >
                <div style={{ alignItems: 'center', display: 'flex', gap: '5px', color: 'grey', lineHeight: 1 }}>
                  <Iconify icon="mdi:map-marker-distance" />
                  {routeParams?.distance}
                </div>
                <div style={{ alignItems: 'center', display: 'flex', gap: '5px', color: 'grey', lineHeight: 1 }}>
                  <Iconify icon="mdi:clock-outline" />
                  {routeParams?.time}
                </div>
              </Box>
            </>
          )}

          {showPrices && (
            <Grid item="true" xs={12} sm={8} sx={{ marginTop: 1 }}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="space-evenly">
                <Grid item="true" xs={4}>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Iconify icon="twemoji:sport-utility-vehicle" />
                    <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                      Carro
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      $ {Intl.NumberFormat('en-DE').format(prices.CAR || '0')}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: 'text.primary', marginTop: 1 }}>
                      Comisión
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      $ {Intl.NumberFormat('en-DE').format((prices.CAR * fee) / 100 || '0')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item="true" xs={4}>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Iconify icon="noto:motor-scooter" />
                    <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                      Moto
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      $ {Intl.NumberFormat('en-DE').format(prices.MOTO || '0')}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: 'text.primary', marginTop: 1 }}>
                      Comisión
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      $ {Intl.NumberFormat('en-DE').format((prices.MOTO * fee) / 100 || '0')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}
