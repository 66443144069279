// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    id: 'dashboard',
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'trips',
  //   id: 'Viajes',
  //   path: '/dashboard/viajes',
  //   icon: icon('ic_ride'),
  // },
  // {
  //   id: 'payments',
  //   title: 'Reporte Pagos',
  //   path: '/dashboard/pagos',
  //   icon: icon('ic_payment'),
  // },
  {
    id: 'users',
    title: 'Usuarias',
    path: '/dashboard/usuarias',
    icon: icon('ic_user'),
  },
  {
    id: 'drivers',
    title: 'Conductoras',
    path: '/dashboard/conductoras',
    icon: icon('ic_user'),
  },
  {
    id: 'fares',
    title: 'Tarifas',
    path: '/dashboard/tarifas',
    icon: icon('ic_fare'),
  },
  // {
  //   id: 'vehicles',
  //   title: 'Carros',
  //   path: '/dashboard/carros',
  //   icon: icon('ic_car'),
  // },
  // {
  //   id: 'promotional_codes',
  //   title: 'Promocional',
  //   path: '/dashboard/promotionalcode',
  //   icon: icon('ic_code'),
  // },
  // {
  //   id: 'driver_rating',
  //   title: 'Raiting Conductor',
  //   path: '/dashboard/raitingconductor',
  //   icon: icon('ic_raiting'),
  // },
  // {
  //   id: 'user_rating',
  //   title: 'Raiting Usuario',
  //   path: '/dashboard/raitingusuario',
  //   icon: icon('ic_raiting'),
  // },
  // {
  //   id: 'notifications',
  //   title: 'Notificaciones',
  //   path: '/dashboard/notificaciones',
  //   icon: icon('ic_notification'),
  // },
];

export default navConfig;
